import { applyMiddleware, combineReducers, createStore } from 'redux';
import { persistReducer, persistStore } from 'redux-persist';
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';
import storageLocal from 'redux-persist/lib/storage';
import ThunkMiddleware from 'redux-thunk';
import PersistenceKey from '../../Helpers/PersistenceKey';

import rootReducer from './rootReducer';

const persistConfigForRoot = (persistenceKey) => ({
    key: persistenceKey.generateRoot(),
    storage: storageLocal,
    stateReconciler: autoMergeLevel2,
    whitelist: ['board', 'advancedSearch', 'savedSearches'],
});

// Entity reducer branch needs its own nested whitelist (due to reducer size)
// We don't want to be needlessly writing 100's KB of data to disk on every props update
const persistConfigForDealBranch = (persistenceKey) => ({
    key: persistenceKey.generateBranch('deal'),
    storage: storageLocal,
    whitelist: ['currentFilter'],
});

// Configure per-user persistence of app
export const configureStore = () => {
    const userIdField = document.getElementsByTagName('body')[0].getAttribute('logged-in-user-id');
    const userId = userIdField ? parseInt(userIdField) : 0;
    const persistenceKey = new PersistenceKey(userId, 'Deals');

    const rootPersistedReducer = persistReducer(
        persistConfigForRoot(persistenceKey),
        combineReducers({
            ...rootReducer,
            deal: persistReducer(persistConfigForDealBranch(persistenceKey), rootReducer.deal), // Swap reducer with persisted version
        }),
    );

    const store = createStore(rootPersistedReducer, applyMiddleware(ThunkMiddleware));
    const persistor = persistStore(store);

    return { store, persistor };
};
