import {
    AppEvent,
    EventBusInstance,
    ExtendedColumn,
    HoveroverButton,
    LogLevel,
    NonSelectableRow,
    showBanner,
    StyledPill,
    User,
    UserFormatter,
} from '@sprint/sprint-react-components';
import CSS from 'csstype';
import React, { FunctionComponent, useContext, useEffect } from 'react';
import { ucwords } from '../../../../Helpers/StringHelper';
import { UserTypeRequest } from '../../Api/UserTypeRequest';
import UniqueKeyBuilder from '../../HelperFunctions/UniqueKeyBuilder';
import UserPermissionsHelper from '../../HelperFunctions/UserPermissionsHelper';
import { PermissionsContext, RepositoryFactoryContext, UserContext } from '../../index';
import { HTMLColors, UniqueKeyType, UserPermissionLevel, UserStatus } from '../../Models/Enums';
import UserType from '../../Models/UserType';
import UserTypeEditState from '../../Models/UserTypeEditState';
import CampusDataGrid from '../CampusDataGrid';
import BillingUserReplacementModal from './BillingUserReplacementModal';
import UsersAddSisp from './UsersAddSisp';
import UsersEditSisp from './UsersEditSisp';

interface Props {
    searchFilterPlaceholder: string;
    dataGridUniqueKey: string;
    dataGridEntitySingular: string;
    dataGridEntityPlural: string;
}

enum UsersColumnKey {
    ID,
    EMAIL,
    FIRST_NAME,
    LAST_NAME,
    REQUIRES_2FA,
    BILLING_USER,
    REQUIRES_OAUTH,
    NAME,
    TYPE,
    STATUS,
    CREATED,
}

interface ResendState {
    id: number;
}

const UsersTable: FunctionComponent<Props> = (props: Props) => {
    const addSispUniqueKey = UniqueKeyBuilder.make(props.dataGridUniqueKey, UniqueKeyType.ADD_SISP);
    const editSispUniqueKey = UniqueKeyBuilder.make(props.dataGridUniqueKey, UniqueKeyType.EDIT_SISP);
    const resendUniqueKey = UniqueKeyBuilder.make(props.dataGridUniqueKey, UniqueKeyType.RESEND_ACTION);

    const usersRepository = useContext(RepositoryFactoryContext).getApiRepository(new UserTypeRequest());
    const user: User = useContext(UserContext);
    const permissions = useContext(PermissionsContext);

    // State: Columns
    const Columns: Record<UsersColumnKey, ExtendedColumn> = {
        [UsersColumnKey.ID]: {
            key: 'id',
            name: 'ID',
            sortable: false,
        },
        [UsersColumnKey.EMAIL]: {
            key: 'email',
            name: 'Email',
            sortable: true,
            width: '3fr',
            renderCell: (props) => {
                const id = (props.row as UserType).id!;
                const email = (props.row as UserType).email;
                const is_master = (props.row as UserType).is_master;
                const first_name = (props.row as UserType).first_name;
                const last_name = (props.row as UserType).last_name;
                const requires_2fa = (props.row as UserType).requires_2fa;
                const billing_user = (props.row as UserType).billing_user;
                const requires_oauth = (props.row as UserType).requires_oauth;
                const user_level = (props.row as UserType).level;
                const status = (props.row as UserType).status;
                const editEventBusMessage: UserTypeEditState = {
                    id,
                    email,
                    first_name,
                    last_name,
                    level: user_level,
                    requires_2fa,
                    billing_user,
                    requires_oauth: requires_oauth ?? false,
                };
                const resendEventBusMessage: ResendState = { id: id };
                return (
                    <>
                        {email}
                        <HoveroverButton
                            contents="Edit"
                            showHoverover={!is_master && user.type === UserPermissionLevel.ADMIN}
                            eventBusMessageTarget={editSispUniqueKey}
                            eventBusMessage={editEventBusMessage}
                        />
                        <HoveroverButton
                            contents="Resend"
                            showHoverover={status === UserStatus.INVITED && user.type === UserPermissionLevel.ADMIN}
                            eventBusMessageTarget={resendUniqueKey}
                            eventBusMessage={resendEventBusMessage}
                        />
                    </>
                );
            },
        },
        [UsersColumnKey.FIRST_NAME]: {
            key: 'first_name',
            name: 'First Name',
            sortable: false,
        },
        [UsersColumnKey.LAST_NAME]: {
            key: 'last_name',
            name: 'Last Name',
            sortable: false,
        },
        [UsersColumnKey.NAME]: {
            key: 'full_name',
            name: 'Name',
            sortable: true,
            renderCell: UserFormatter,
            width: '2fr',
        },
        [UsersColumnKey.TYPE]: {
            key: 'user_level',
            name: 'Type',
            sortable: true,
            width: '1fr',
            renderCell: (props) => {
                return <>{ucwords((props.row as UserType).level)}</>;
            },
        },
        [UsersColumnKey.REQUIRES_2FA]: {
            key: 'requires_2fa',
            name: 'Two Factor Authentication',
            sortable: false,
            width: '2fr',
            renderCell: (props) => {
                return <span>{(props.row as UserType).requires_2fa ? 'Enabled' : 'Disabled'}</span>;
            },
        },
        [UsersColumnKey.BILLING_USER]: {
            key: 'billing_user',
            name: 'Billing User',
            sortable: false,
            width: '1fr',
            renderCell: (props) => {
                return <span>{(props.row as UserType).billing_user ? 'Yes' : 'No'}</span>;
            },
        },
        [UsersColumnKey.REQUIRES_OAUTH]: {
            key: 'requires_oauth',
            name: 'Require OAuth',
            sortable: false,
            width: '1fr',
            renderCell: (props) => {
                return <span>{(props.row as UserType).requires_oauth ? 'Yes' : 'No'}</span>;
            },
        },
        [UsersColumnKey.STATUS]: {
            key: 'status',
            name: 'Status',
            sortable: true,
            width: '1fr',
            renderCell: (props) => {
                const status = (props.row as UserType).status;
                const pillStyle: CSS.Properties = {
                    backgroundColor: status === UserStatus.ACTIVE ? HTMLColors.SUCCESS : HTMLColors.PRIMARY,
                };
                return <StyledPill cellContent={status} style={pillStyle} />;
            },
        },
        [UsersColumnKey.CREATED]: {
            key: 'created',
            name: 'Created',
            sortable: true,
            width: '1fr',
        },
    };

    const DefaultColumns: ExtendedColumn[] = [
        Columns[UsersColumnKey.EMAIL],
        Columns[UsersColumnKey.NAME],
        Columns[UsersColumnKey.TYPE],
        Columns[UsersColumnKey.BILLING_USER],
        Columns[UsersColumnKey.REQUIRES_OAUTH],
        Columns[UsersColumnKey.STATUS],
        Columns[UsersColumnKey.CREATED],
    ];

    if (permissions['two_factor_visible']) {
        DefaultColumns.splice(2, 0, Columns[UsersColumnKey.REQUIRES_2FA]);
    }

    useEffect(() => {
        EventBusInstance.subscribe('show-hoverover-component', (event: AppEvent<ResendState>) => {
            if (event.target !== resendUniqueKey) return;
            const id = event.message.id;
            usersRepository
                .post_action('resend', id)
                .then(() => {
                    showBanner({
                        message: 'Successfully resent invitation email',
                        level: LogLevel.SUCCESS,
                    });
                    return true;
                })
                .catch((err: any) => {
                    showBanner({
                        message: err?.message ?? err,
                        level: LogLevel.ERROR,
                    });
                    return false;
                });
        });
    }, []);

    const findNonSelectableRows = (rows: any): NonSelectableRow[] | null => {
        return UserPermissionsHelper.isUserAnAdmin(user)
            ? rows
                  ?.filter((row: any) => (row as UserType).is_master)
                  .map((row: any) => ({ id: row.id, reason: 'Master user cannot be deleted' }))
            : rows?.map((row: any) => ({ id: row.id, reason: 'Permission denied' }));
    };

    return (
        <>
            <CampusDataGrid
                repository={usersRepository}
                dataGridMeta={{
                    uniqueKey: props.dataGridUniqueKey,
                    entitySingular: props.dataGridEntitySingular,
                    entityPlural: props.dataGridEntityPlural,
                    defaultColumns: DefaultColumns,
                    columnOptions: Columns,
                    nonSelectableRows: findNonSelectableRows,
                }}
                actionBarMeta={{
                    searchPlaceHolder: props.searchFilterPlaceholder,
                    includeCounts: true,
                    addButtonOverride: UserPermissionsHelper.isUserAnAdmin(user) ? undefined : <></>, //hide the add button if needed
                }}
                addSispMeta={
                    UserPermissionsHelper.isUserAnAdmin(user)
                        ? {
                              key: addSispUniqueKey,
                              sisp: UsersAddSisp,
                          }
                        : undefined
                }
                editSispMeta={{
                    sisp: UsersEditSisp,
                }}
                limitsModalMeta={{ limits: [{ limitApiKey: 'users_limit' }] }}
                deleteModalMeta={{ modal: BillingUserReplacementModal }}
            />
        </>
    );
};

export default UsersTable;
