import { ClientGroupsRequest } from '@client/Applications/DataGrids/Api/ClientGroupsRequest';
import ClientGroup from '@client/Applications/DataGrids/Models/ClientGroup';
import { ClientGroupType } from '@client/Applications/DataGrids/Models/Enums';
import { faCircle, faThumbtack } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    EntityTarget,
    LogLevel,
    ProfileAddEditModal,
    ProfileAddEditModalType,
    SavedProfile,
    SearchQuery,
    SearchQueryBuilder,
    User,
    filter as filterTypes,
    isProfileOwnedByMe,
    showBanner,
} from '@sprint/sprint-react-components';
import _ from 'lodash';
import React, { FunctionComponent, useContext, useEffect, useState } from 'react';
import { Dropdown } from 'react-bootstrap';
import DataGridRequest from '../Api/DataGridRequest';
import { RepositoryFactoryContext, UserContext } from '../index';
import { SavedInboundView, SavedViewColumns } from './SavedInboundView';
import './ViewsBar.scss';
import ViewsModal, { SavedViewType } from './ViewsModal';

interface Props {
    entityNamePlural: string;
    selectedColumns: SavedViewColumns[];
    selectedFiltersState: filterTypes.SelectedFilters[];
    viewsTarget: EntityTarget;
    targetDisplayStringSingular: string;
    targetDisplayStringPlural: string;
    activeView: SavedInboundView;
    onViewSelected: (view?: SavedInboundView) => void;
    onViewUpdated: () => void; // triggered when a View is deleted or added
    listLimitReached: boolean;
    limitReachedHandler: () => void;
    count: number;
    request: DataGridRequest<SavedInboundView>;
    pinnedFirstTab?: JSX.Element;
    savedViewType: SavedViewType;
    forceLoadViewId?: number;
    updateViewOnSelect?: boolean;
    groupType?: ClientGroupType;
}

const ViewsBar: FunctionComponent<Props> = (props: Props) => {
    const savedViewsRepository = useContext(RepositoryFactoryContext).getApiRepository<SavedInboundView>(props.request);
    const customPropertiesClientGroupsRepository = useContext(RepositoryFactoryContext).getApiRepository(
        new ClientGroupsRequest(props.groupType ?? ''),
    );
    const user: User = useContext(UserContext);

    const [showViewsModal, setShowViewsModal] = useState(false);
    const [showAddModal, setShowAddModal] = useState(false);
    const [showExtraActiveViewTab, setShowExtraActiveViewTab] = useState(false);

    const [fetchDataSuccess, setFetchDataSuccess] = useState<boolean>(true);

    const [allViews, setAllViews] = useState<SavedInboundView[]>([]);
    const [viewFilter, setViewFilter] = useState('');

    const tableKey = props.viewsTarget;
    const activeViewStorageKey = `persistActiveView-${tableKey}-${user?.id}`;

    const query = new SearchQueryBuilder(1, 1000).build();
    query.setExtendedParameters({ viewsTarget: props.viewsTarget });

    const getViews = (preSelectedViewId?: number) => {
        savedViewsRepository
            .search(query)
            .then(({ results }) => {
                // check ' + props.targetDisplayStringSingular.toLowerCase() + ' limits
                props.onViewUpdated();
                setAllViews(results);

                if (preSelectedViewId) {
                    const preSelectedView = _.find(results, { id: preSelectedViewId });
                    if (preSelectedView) {
                        props.onViewSelected(preSelectedView);
                    } else {
                        showBanner({
                            message: `Could not find ${props.targetDisplayStringSingular.toLowerCase()}.`,
                        });
                    }
                }
            })
            .catch((err) => {
                setFetchDataSuccess(false);
                showBanner({
                    message:
                        'Could not find ' +
                        props.targetDisplayStringSingular.toLowerCase() +
                        ' - ' +
                        (err?.message ?? err),
                });
            });
    };

    const updateViewHandler = (view: SavedInboundView, viewSelect: boolean = false): Promise<boolean> => {
        if (view.id) {
            if (!view.selectedFilters) view.selectedFilters = props.selectedFiltersState;
            if (!view.dataGridColumns) view.dataGridColumns = props.selectedColumns;

            return savedViewsRepository
                .update(view)
                .then((updatedView) => {
                    const response = updatedView as unknown as any;
                    if (response.success === false) {
                        showBanner({
                            message:
                                'Could not save ' +
                                props.targetDisplayStringSingular.toLowerCase() +
                                ' - ' +
                                (response.error ?? 'an unknown error occurred'),
                        });
                        return false;
                    } else {
                        if (!viewSelect) {
                            showBanner({
                                message:
                                    'Your ' +
                                    props.targetDisplayStringSingular.toLowerCase() +
                                    ' has been successfully saved.',
                                level: LogLevel.SUCCESS,
                            });

                            // set created view as the now active view
                            props.onViewSelected({ ...updatedView, selectedFilters: updatedView.selectedFilters });
                            getViews();
                        }
                        return true;
                    }
                })
                .catch((err) => {
                    showBanner({
                        message:
                            'Could not save ' +
                            props.targetDisplayStringSingular.toLowerCase() +
                            ' - ' +
                            (err?.message ?? err),
                    });
                    return false;
                });
        } else {
            view.selectedFilters = props.selectedFiltersState;
            view.dataGridColumns = props.selectedColumns;

            return savedViewsRepository
                .create(view)
                .then((createdView) => {
                    showBanner({
                        message:
                            'Your ' + props.targetDisplayStringSingular.toLowerCase() + ' has been successfully saved.',
                        level: LogLevel.SUCCESS,
                    });
                    // set created view as the now active view
                    props.onViewSelected({ ...createdView, selectedFilters: createdView.selectedFilters });
                    getViews();
                    return true;
                })
                .catch((err) => {
                    showBanner({
                        message:
                            'Could not save ' +
                            props.targetDisplayStringSingular.toLowerCase() +
                            ' - ' +
                            (err?.message ?? err),
                    });
                    return false;
                });
        }
    };

    const saveViewHandler = (isSaveAs: boolean) => {
        if (isSaveAs) {
            setShowAddModal(true);
        } else {
            if (props.activeView && props.activeView.id) {
                updateViewHandler({ id: props.activeView.id });
            }
        }
    };

    const closeProfileView = (value: boolean) => {
        setShowAddModal(value);
    };

    let mounted = false;
    // load the view enforced by props or the last used view
    useEffect(() => {
        const activeViewLsItem = localStorage.getItem(activeViewStorageKey) ?? undefined;
        const activeViewId = activeViewLsItem ? Number.parseInt(activeViewLsItem) : undefined;
        getViews(props.forceLoadViewId ?? activeViewId);
        mounted = true;
    }, []);

    // save active view as the default view (if it gets set to that)
    useEffect(() => {
        localStorage.setItem(activeViewStorageKey, String(props.activeView.id));
        if (props.updateViewOnSelect && props.activeView.id !== 0 && mounted) {
            updateViewHandler({ ...props.activeView }, true);
        }
    }, [props.activeView]);

    // Only show active view tab if not already pinned
    useEffect(() => {
        setShowExtraActiveViewTab(() => {
            if (props.activeView.id === 0) return false;
            return !props.activeView.pinned ?? true;
        });
    }, [props.activeView]);

    // sort views into their groups
    useEffect(() => {
        if (allViews != undefined) {
            // update active view if it was the one updated
            if (props.activeView && props.activeView.id !== 0) {
                props.onViewSelected(_.filter(allViews, (view) => view.id === props.activeView?.id)[0]);
            }
        }
    }, [allViews, viewFilter]);

    const pinnedViews = _.filter(allViews, (view: SavedInboundView) => view.pinned);

    const tabMaxWidth = `calc((100% - 57px) / ${
        showExtraActiveViewTab ? pinnedViews.length + 2 : pinnedViews.length + 1
    })`;

    // Update the pinned & name values for views on the frontend
    const onTogglePinned = (profile: SavedProfile) => {
        // Find view
        const view = _.filter(allViews, (view: SavedInboundView) => {
            return view.id == profile.id;
        })[0];
        // Check if 'pinned' or 'name' value has changed
        if (view.pinned != profile.pinned || view.name !== profile.name) {
            // Create copy of existing views
            const i: number = _.indexOf(allViews, view);
            const updatedView = allViews[i];
            // Update values
            updatedView.pinned = profile.pinned;
            updatedView.name = profile.name;
            // Replace old view with updated one
            allViews.splice(i, 1, updatedView);
            setAllViews(allViews);
            // Only show active view tab if not already pinned
            setShowExtraActiveViewTab(() => {
                if (props.activeView.id === 0) return false;
                const view: SavedInboundView | undefined = _.find(
                    allViews,
                    (view: SavedInboundView) => view.id == props.activeView.id,
                );
                return !view?.pinned ?? true;
            });
        }
    };

    const checkProfileNameIsUnique = async (name: string): Promise<boolean> => {
        return savedViewsRepository
            .post_action('check_name', undefined, { name: name })
            .then((results: any) => {
                return results.data.result;
            })
            .catch(() => {
                showBanner({
                    message: `Unable to check ${props.targetDisplayStringSingular} name`,
                    level: LogLevel.ERROR,
                });
                closeProfileView(false);
                return false;
            });
    };

    const getFolderOptions = async (filter: string) => {
        const query = new SearchQuery(1, 1000, undefined, undefined, filter);
        return customPropertiesClientGroupsRepository
            .search(query)
            .then((results: any) => {
                return {
                    options: results.results.map((result: ClientGroup) => {
                        return { value: result.id, label: result.name };
                    }),
                };
            })
            .catch((err: any) => {
                return null;
            });
    };

    return fetchDataSuccess ? (
        <>
            <ViewsModal
                request={props.request}
                modalShown={showViewsModal}
                onClose={() => setShowViewsModal(false)}
                viewsTarget={props.viewsTarget}
                viewFilter={viewFilter}
                getViews={getViews}
                onUpdateView={updateViewHandler}
                onViewSelected={(view) => {
                    props.onViewSelected(view);
                    setShowViewsModal(false);
                }}
                onViewFilter={setViewFilter}
                onTogglePinned={onTogglePinned}
                activeViewStorageKey={activeViewStorageKey}
                onRowsDeleted={getViews}
                title={`${props.entityNamePlural} Saved ${props.targetDisplayStringPlural}`}
                savedViewType={props.savedViewType}
                entityTypeSingular={props.targetDisplayStringSingular}
                entityTypePlural={props.targetDisplayStringPlural}
                groupType={props.groupType}
            />
            <ProfileAddEditModal
                modalType={
                    props.savedViewType == SavedViewType.MARKETING_LIST
                        ? ProfileAddEditModalType.LIST
                        : ProfileAddEditModalType.SAVED_SEARCH
                }
                profile={{
                    target: props.viewsTarget,
                }}
                modalShown={showAddModal}
                closeModal={() => closeProfileView(false)}
                onSubmit={updateViewHandler}
                checkProfileNameIsUnique={checkProfileNameIsUnique}
                user={user}
                entityTypeSingular={props.targetDisplayStringSingular}
                getAvailableFolders={props.groupType ? getFolderOptions : undefined}
            />
            <div className="views-bar">
                <ul className="nav nav-tabs view-tabs">
                    {props.pinnedFirstTab && <li className="nav-item view-tab-icon">{props.pinnedFirstTab}</li>}
                    <li
                        className={`nav-item ${props.activeView.id === 0 ? 'active' : ''}`}
                        style={{ maxWidth: tabMaxWidth }}
                    >
                        <a className="nav-link" href="#" onClick={() => props.onViewSelected()}>
                            All {props.entityNamePlural}
                        </a>
                    </li>
                    {_.map(pinnedViews, (view: SavedInboundView, index: string) => {
                        const activeClass = view.id === props.activeView.id ? 'active' : '';
                        return (
                            <li
                                key={'pinned' + index}
                                className={`nav-item ${activeClass}`}
                                style={{ maxWidth: tabMaxWidth }}
                                onClick={() => props.onViewSelected(view)}
                            >
                                <a href="#" onClick={() => props.onViewSelected(view)}>
                                    <span
                                        className="fa-layers fa-fw fa-lg"
                                        style={{ fontSize: '1.5em', position: 'absolute', marginLeft: '-3px' }}
                                    >
                                        <FontAwesomeIcon icon={faCircle} style={{ color: '#09c' }} />
                                        <FontAwesomeIcon
                                            icon={faThumbtack}
                                            style={{ transform: 'rotate(-45deg)' }}
                                            inverse
                                            transform="shrink-8"
                                        />
                                    </span>{' '}
                                    <div style={{ marginLeft: '24px' }}>{view.name}</div>
                                </a>
                            </li>
                        );
                    })}
                    {showExtraActiveViewTab && (
                        <li className="nav-item active" style={{ maxWidth: tabMaxWidth }}>
                            <a href="#">{props.activeView.name}</a>
                        </li>
                    )}
                </ul>
                <div className="actions-tabs">
                    <div className="view-tab all" onClick={() => setShowViewsModal(true)}>
                        All {props.targetDisplayStringPlural}
                    </div>
                    {props.activeView && isProfileOwnedByMe(props.activeView, user) ? (
                        <Dropdown className="view-tab button-dropdown save-dropdown">
                            <Dropdown.Toggle>+ Save {props.targetDisplayStringSingular}</Dropdown.Toggle>
                            <Dropdown.Menu>
                                <Dropdown.Item onClick={() => saveViewHandler(false)}>Save</Dropdown.Item>
                                <Dropdown.Item
                                    onClick={() => {
                                        props.listLimitReached ? props.limitReachedHandler() : saveViewHandler(true);
                                    }}
                                >
                                    Save As{' '}
                                    {props.listLimitReached && (
                                        <span>
                                            <i className={'fal fa-lock'} style={{ marginLeft: '2px' }} />
                                        </span>
                                    )}
                                </Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    ) : (
                        <div
                            className="view-tab save"
                            onClick={() => {
                                props.listLimitReached ? props.limitReachedHandler() : saveViewHandler(true);
                            }}
                        >
                            + Save {props.targetDisplayStringSingular}
                            {props.listLimitReached && (
                                <span>
                                    <i className={'fal fa-lock'} style={{ marginLeft: '6px' }} />
                                </span>
                            )}
                        </div>
                    )}
                </div>
            </div>
        </>
    ) : (
        <></>
    );
};

export default ViewsBar;
