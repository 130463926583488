import { Banner, User } from '@sprint/sprint-react-components';
import React, { Context, createContext } from 'react';
import { createRoot } from 'react-dom/client';
import { ucwords } from '../../Helpers/StringHelper';
import '../EducationDataGrid/EducationDataGrid.scss';
import IClientLimits from '../EducationDataGrid/Interfaces/IClientLimit';
import RepositoryFactory, { DataGridApiRepositoryFactory } from './Api/DataGridApiRepositoryFactory';
import DualTabClientGroups, {
    ClientGroupsGroupsDataGridMeta,
    ClientGroupsMainDataGridMeta,
} from './Components/DualTabClientGroups';
import { DataGridFactory } from './DataGridFactory';
import ContactsTable from './Grids/CRM/ContactsTable';
import CrmListsTable from './Grids/CRM/CrmListsTable';
import OrganisationsTable from './Grids/CRM/OrganisationsTable';
import CampaignsTable from './Grids/Campaigns/CampaignsTable';
import ClientTagsTable from './Grids/ClientTags/ClientTagsTable';
import CustomPropertiesTable from './Grids/CustomProperties/CustomPropertiesTable';
import DealsTable from './Grids/Deals/DealsTable';
import DealsTableCollection from './Grids/DealsTableCollection';
import EmailsTable from './Grids/Emails/EmailsTable';
import EngagementMethodsTable from './Grids/EngagementMethods/EngagementMethodsTable';
import FormSubmissionsTable from './Grids/Forms/FormSubmissionsTable';
import FormsTable from './Grids/Forms/FormsTable';
import GlossaryTable from './Grids/Glossary/GlossaryTable';
import ImportTable from './Grids/Import/ImportTable';
import PaymentTypesTable from './Grids/PaymentTypes/PaymentTypesTable';
import PaymentsTable from './Grids/Payments/PaymentsTable';
import ProductsTable from './Grids/Products/ProductsTable';
import QuotesTable from './Grids/Quotes/QuotesTable';
import ReplyToAddressTable from './Grids/ReplyToAddress/ReplyToAddressTable';
import SalesTable from './Grids/Sales/SalesTable';
import SmtpAccountTable from './Grids/SmptAccounts/SmtpAccountTable';
import TaskTypesTable from './Grids/TaskTypes/TaskTypesTable';
import TasksTable from './Grids/Tasks/TasksTable';
import TemplatesTable from './Grids/Templates/TemplatesTable';
import UnsubscribedContacts from './Grids/Unsubscribes/UnsubscribedContacts';
import UnsubscribedTable from './Grids/Unsubscribes/UnsubscribedTable';
import UrlsTable from './Grids/Urls/UrlsTable';
import UsersTable from './Grids/Users/UsersTable';
import VatRatesTable from './Grids/VatRates/VatRatesTable';
import { ClientGroupType, UnsubscribedType } from './Models/Enums';

export let UserContext: Context<User>;
if (document.getElementById('user-blob')) {
    const user: User = JSON.parse(String((document.getElementById('user-blob') as HTMLInputElement).value)) as User;
    if (user) UserContext = createContext(user);
}

export let LimitsContext: Context<IClientLimits> = createContext({});
if (document.getElementById('client-limits')) {
    const limits = JSON.parse(String((document.getElementById('client-limits') as HTMLInputElement).value));
    if (limits) LimitsContext = createContext(limits);
}

// TODO CC-7547
export let PermissionsContext: Context<any>;
if (document.getElementById('module-permissions')) {
    const permissions = JSON.parse(String((document.getElementById('module-permissions') as HTMLInputElement).value));
    if (permissions) PermissionsContext = createContext(permissions);
} else if (document.getElementById('user-permissions')) {
    const permissions = JSON.parse(String((document.getElementById('user-permissions') as HTMLInputElement).value));
    if (permissions) PermissionsContext = createContext(permissions);
}

export let DictionaryContext: Context<any>;
let dictionary: any | undefined;
if (document.getElementById('dictionary')) {
    dictionary = JSON.parse(String((document.getElementById('dictionary') as HTMLInputElement).value));
    if (dictionary) DictionaryContext = createContext(dictionary);
}

let oauthEmailEnabled = false;
if (document.getElementById('oauth-email-enabled')) {
    const value = (document.getElementById('oauth-email-enabled') as HTMLInputElement).value;
    oauthEmailEnabled = !!value;
}

const repositoryFactory: RepositoryFactory = new DataGridApiRepositoryFactory();
export const RepositoryFactoryContext: Context<RepositoryFactory> = createContext(repositoryFactory);

const taskTypesTableContainer: HTMLElement | null = document.getElementById('TaskTypesTable');

if (taskTypesTableContainer) {
    const root = createRoot(taskTypesTableContainer);
    root.render(
        <>
            <h4 className="datatable-settings-header">Task Types</h4>
            <Banner />
            <TaskTypesTable
                searchFilterPlaceholder="Search Task Type"
                dataGridUniqueKey="TaskType"
                dataGridEntitySingular="Task Type"
                dataGridEntityPlural="Task Types"
            />
        </>,
    );
}

const contactsTableContainer: HTMLElement | null = document.getElementById('ContactsTable');

if (contactsTableContainer) {
    const customProperties: any = JSON.parse(
        String((document.getElementById('custom-properties') as HTMLInputElement).value),
    );
    const creationLocked: any = JSON.parse((document.getElementById('creation-locked') as HTMLInputElement).value);
    const showAddSisp: boolean = contactsTableContainer.dataset.action == 'add';

    const viewIdValue = (document.getElementById('active-view-id') as HTMLInputElement).value;
    const activeViewId: any = viewIdValue ? JSON.parse(viewIdValue) : undefined;

    const root = createRoot(contactsTableContainer);
    root.render(
        <>
            <h1 className="page-header">Contacts</h1>
            <Banner />
            <ContactsTable
                searchFilterPlaceholder="Search Name, Email, or Organisation"
                dataGridUniqueKey="Contacts"
                dataGridEntityPlural="Contacts"
                dataGridEntitySingular="Contact"
                customProperties={customProperties}
                creationLocked={creationLocked}
                showAddSisp={showAddSisp}
                activeViewId={activeViewId}
            />
        </>,
    );
}

const organisationsTableContainer: HTMLElement | null = document.getElementById('OrganisationsTable');

if (organisationsTableContainer) {
    const customProperties: any = JSON.parse(
        String((document.getElementById('custom-properties') as HTMLInputElement).value),
    );
    const creationLocked: any = JSON.parse((document.getElementById('creation-locked') as HTMLInputElement).value);
    const showAddSisp: boolean = organisationsTableContainer.dataset.action == 'add';

    const viewIdValue = (document.getElementById('active-view-id') as HTMLInputElement).value;
    const activeViewId: any = viewIdValue ? JSON.parse(viewIdValue) : undefined;

    const root = createRoot(organisationsTableContainer);
    root.render(
        <>
            <h1 className="page-header">Organisations</h1>
            <Banner />
            <OrganisationsTable
                searchFilterPlaceholder={
                    'Search ' + dictionary ? ucwords(dictionary['organisations']) : 'Organisations'
                }
                dataGridUniqueKey={dictionary ? ucwords(dictionary['organisations']) : 'Organisations'}
                dataGridEntityPlural={dictionary ? ucwords(dictionary['organisations']) : 'Organisations'}
                dataGridEntitySingular={dictionary ? ucwords(dictionary['organisation']) : 'Organisation'}
                customProperties={customProperties}
                creationLocked={creationLocked}
                showAddSisp={showAddSisp}
                activeViewId={activeViewId}
            />
        </>,
    );
}

const crmListsTableContainer: HTMLElement | null = document.getElementById('CrmListsTable');

if (crmListsTableContainer) {
    const root = createRoot(crmListsTableContainer);

    const mainDataGridMeta: ClientGroupsMainDataGridMeta = {
        dataGrid: CrmListsTable,
        searchFilterPlaceholder: 'Search Email or Subject Line',
        dataGridUniqueKey: 'Lists',
        dataGridEntitySingular: 'List',
        dataGridEntityPlural: 'Lists',
    };
    const groupsDataGridMeta: ClientGroupsGroupsDataGridMeta = {
        dataGridEntitySingular: 'Folder',
        dataGridEntityPlural: 'Folders',
    };

    root.render(
        <>
            <h1 className="page-header">Lists</h1>
            <Banner />
            <DualTabClientGroups
                mainDataGridMeta={mainDataGridMeta}
                clientGroupType={ClientGroupType.INBOUND_LISTS}
                groupDataGridMeta={groupsDataGridMeta}
            />
        </>,
    );
}

const productsTableContainer: HTMLElement | null = document.getElementById('ProductsTable');

if (productsTableContainer) {
    const root = createRoot(productsTableContainer);
    root.render(
        <>
            <h4 className="datatable-settings-header">Products</h4>
            <Banner />
            <ProductsTable
                searchFilterPlaceholder="Search Name or Description"
                dataGridUniqueKey="Product"
                dataGridEntitySingular="Product"
                dataGridEntityPlural="Products"
            />
        </>,
    );
}

const paymentTypesTableContainer: HTMLElement | null = document.getElementById('PaymentTypesTable');

if (paymentTypesTableContainer) {
    const root = createRoot(paymentTypesTableContainer);
    root.render(
        <>
            <h4 className="datatable-settings-header">Payment Types</h4>
            <Banner />
            <PaymentTypesTable
                searchFilterPlaceholder="Search Payment Type"
                dataGridUniqueKey="PaymentType"
                dataGridEntitySingular="Payment Type"
                dataGridEntityPlural="Payment Types"
            />
        </>,
    );
}

const vatRatesTableContainer: HTMLElement | null = document.getElementById('VatRatesTable');

if (vatRatesTableContainer) {
    const root = createRoot(vatRatesTableContainer);
    root.render(
        <>
            <h4 className="datatable-settings-header">{dictionary ? ucwords(dictionary['tax']) : 'Tax'} Rates</h4>
            <Banner />
            <VatRatesTable
                searchFilterPlaceholder="Search Tax Rate"
                dataGridUniqueKey="VatRate"
                dataGridEntitySingular={dictionary ? ucwords(dictionary['tax']) + ' Rate' : 'Tax Rate'}
                dataGridEntityPlural={dictionary ? ucwords(dictionary['tax']) + ' Rates' : 'Tax Rates'}
            />
        </>,
    );
}

const usersTableContainer: HTMLElement | null = document.getElementById('UsersTable');

if (usersTableContainer) {
    const root = createRoot(usersTableContainer);
    root.render(
        <>
            <h1 className="page-header">Users</h1>
            <Banner />
            <UsersTable
                searchFilterPlaceholder="Search User, Email, or Type"
                dataGridUniqueKey="Users"
                dataGridEntitySingular="User"
                dataGridEntityPlural="Users"
            />
        </>,
    );
}

const billingTableContainer: HTMLElement | null = document.getElementById('BillingTable');
if (billingTableContainer) {
    const root = createRoot(billingTableContainer);
    root.render(
        <>
            <h4 className="datatable-settings-header">Billing History</h4>
            <p>To get your latest invoice please contact your account manager.</p>
        </>,
    );
}

const unsubscribedTeachersContainer: HTMLElement | null = document.getElementById('UnsubscribedTeachers');
if (unsubscribedTeachersContainer) {
    const root = createRoot(unsubscribedTeachersContainer);
    root.render(
        <>
            <h4 className="page-header">Unsubscribed {dictionary ? ucwords(dictionary['teachers']) : 'Teachers'}</h4>
            <p className="datagrid-help-text">
                These are the {dictionary ? ucwords(dictionary['teachers']) : 'Teachers'} that have unsubscribed from
                your outreach marketing.
            </p>
            <Banner />
            <UnsubscribedTable
                searchFilterPlaceholder="Search Name or Email"
                dataGridUniqueKey="TeacherUnsubscribes"
                dataGridEntitySingular="Unsubscribe"
                dataGridEntityPlural="Unsubscribes"
                type={UnsubscribedType.TEACHERS}
            />
        </>,
    );
}
const unsubscribedSchoolsContainer: HTMLElement | null = document.getElementById('UnsubscribedSchools');
if (unsubscribedSchoolsContainer) {
    const root = createRoot(unsubscribedSchoolsContainer);
    root.render(
        <>
            <h4 className="page-header">Unsubscribed {dictionary ? ucwords(dictionary['schools']) : 'Schools'}</h4>
            <p className="datagrid-help-text">
                These are the {dictionary ? ucwords(dictionary['schools']) : 'Schools'} that have unsubscribed from your
                outreach marketing.
            </p>
            <Banner />
            <UnsubscribedTable
                searchFilterPlaceholder="Search Name or Email"
                dataGridUniqueKey="SchoolUnsubscribes"
                dataGridEntitySingular="Unsubscribe"
                dataGridEntityPlural="Unsubscribes"
                type={UnsubscribedType.SCHOOLS}
            />
        </>,
    );
}
const unsubscribedContactsContainer: HTMLElement | null = document.getElementById('UnsubscribedContacts');
if (unsubscribedContactsContainer) {
    const root = createRoot(unsubscribedContactsContainer);
    root.render(
        <>
            <h4 className="datatable-settings-header">Unsubscribes</h4>
            <Banner />
            <UnsubscribedContacts
                searchFilterPlaceholder={`Search Name, Email or ${ucwords(dictionary['organisation'])}`}
                dataGridUniqueKey="ContactUnsubscribes"
                dataGridEntitySingular="Unsubscribe"
                dataGridEntityPlural="Unsubscribes"
                type={UnsubscribedType.CONTACTS}
            />
        </>,
    );
}

const importTableContainer: HTMLElement | null = document.getElementById('ImportTable');

if (importTableContainer) {
    const root = createRoot(importTableContainer);
    root.render(
        <>
            <h4>Previous Imports</h4>
            <Banner />
            <ImportTable
                searchFilterPlaceholder="Search Name or Type"
                dataGridUniqueKey="Import"
                dataGridEntitySingular="Import"
                dataGridEntityPlural="Imports"
            />
        </>,
    );
}

const importReviewTableContainer: HTMLElement | null = document.getElementById('ImportReviewTable');

if (importReviewTableContainer) {
    const root = createRoot(importReviewTableContainer);
    const import_id: number = JSON.parse(String((document.getElementById('import_id') as HTMLInputElement).value));
    root.render(
        <>
            <Banner />
            {DataGridFactory.import_review(import_id)}
        </>,
    );
}

const urlsTableContainer: HTMLElement | null = document.getElementById('UrlsTable');

if (urlsTableContainer) {
    const root = createRoot(urlsTableContainer);
    root.render(
        <>
            <h4 className="datatable-settings-header">URLs</h4>
            <Banner />
            <UrlsTable
                searchFilterPlaceholder="Search Name or URL"
                dataGridUniqueKey="URLs"
                dataGridEntitySingular="URL"
                dataGridEntityPlural="URLs"
            />
        </>,
    );
}

const emailsTableContainer: HTMLElement | null = document.getElementById('EmailsTable');

if (emailsTableContainer) {
    const root = createRoot(emailsTableContainer);
    const mainDataGridMeta: ClientGroupsMainDataGridMeta = {
        dataGrid: EmailsTable,
        searchFilterPlaceholder: 'Search Email or Subject Line',
        dataGridUniqueKey: 'emails',
        dataGridEntitySingular: 'Email',
        dataGridEntityPlural: 'Emails',
    };
    const groupsDataGridMeta: ClientGroupsGroupsDataGridMeta = {
        dataGridEntitySingular: 'Folder',
        dataGridEntityPlural: 'Folders',
    };

    root.render(
        <>
            <h1 className="page-header">Emails</h1>
            <Banner />
            <DualTabClientGroups
                mainDataGridMeta={mainDataGridMeta}
                clientGroupType={ClientGroupType.EMAILS}
                groupDataGridMeta={groupsDataGridMeta}
            />
        </>,
    );
}

const dealTableContainer: HTMLElement | null = document.getElementById('DealsTableCollection');
if (dealTableContainer) {
    const root = createRoot(dealTableContainer);
    root.render(
        <>
            <DealsTableCollection />
        </>,
    );
}

const campaignsTableContainer: HTMLElement | null = document.getElementById('CampaignsTable');

if (campaignsTableContainer) {
    const root = createRoot(campaignsTableContainer);
    root.render(
        <>
            <h1 className="page-header">Campaigns</h1>
            <Banner />
            <CampaignsTable
                searchFilterPlaceholder="Search Email, List"
                dataGridUniqueKey="Campaigns"
                dataGridEntitySingular="Campaign"
                dataGridEntityPlural="Campaigns"
            />
        </>,
    );
}

const formSubmissionsTableContainer: HTMLElement | null = document.getElementById('FormSubmissionsTable');

if (formSubmissionsTableContainer) {
    const root = createRoot(formSubmissionsTableContainer);
    root.render(
        <>
            <h1 className="page-header">Form Submissions</h1>
            <Banner />
            <FormSubmissionsTable
                searchFilterPlaceholder="Search Form Name, Contact, or Organisation"
                dataGridUniqueKey="FormSubmissions"
                dataGridEntitySingular="Submission"
                dataGridEntityPlural="Submissions"
            />
        </>,
    );
}

const replyToAddressTableContainer: HTMLElement | null = document.getElementById('ReplyToAddressTable');

if (replyToAddressTableContainer) {
    const root = createRoot(replyToAddressTableContainer);
    root.render(
        <>
            <h4 className="datatable-settings-header">Reply To Email Addresses</h4>
            <Banner />
            <ReplyToAddressTable
                searchFilterPlaceholder="Search Emails"
                dataGridUniqueKey="ReplyToAddress"
                dataGridEntitySingular="Email"
                dataGridEntityPlural="Emails"
            />
        </>,
    );
}

const customPropertiesTableContainer: HTMLElement | null = document.getElementById('CustomPropertiesTable');
if (customPropertiesTableContainer) {
    const root = createRoot(customPropertiesTableContainer);
    const mainDataGridMeta: ClientGroupsMainDataGridMeta = {
        dataGrid: CustomPropertiesTable,
        searchFilterPlaceholder: 'Search Custom Properties, Type, or Data Type',
        dataGridUniqueKey: 'CustomProperties',
        dataGridEntitySingular: 'Custom Property',
        dataGridEntityPlural: 'Custom Properties',
    };

    root.render(
        <>
            <h4 className="datatable-settings-header">Custom Properties</h4>
            <Banner />
            <DualTabClientGroups
                mainDataGridMeta={mainDataGridMeta}
                clientGroupType={ClientGroupType.CUSTOM_PROPERTIES}
                mainTabLabel="Properties"
            />
        </>,
    );
}

const formsTableContainer: HTMLElement | null = document.getElementById('FormsTable');

if (formsTableContainer) {
    const root = createRoot(formsTableContainer);
    root.render(
        <>
            <h1 className="page-header">Forms</h1>
            <Banner />
            <FormsTable
                searchFilterPlaceholder="Search Form Name or Description"
                dataGridUniqueKey="Forms"
                dataGridEntitySingular="Form"
                dataGridEntityPlural="Forms"
            />
        </>,
    );
}

const smtpAccountsTableContainer: HTMLElement | null = document.getElementById('SmtpAccountsTable');

if (smtpAccountsTableContainer) {
    const root = createRoot(smtpAccountsTableContainer);
    root.render(
        <>
            <h4 className="datatable-settings-header">Connected Emails</h4>
            <p>Connect your email account to Campus to log, send, and receive emails in Campus.</p>
            <Banner />
            <SmtpAccountTable
                searchFilterPlaceholder="Search Email or Host"
                dataGridUniqueKey="ConnectedEmails"
                dataGridEntitySingular="Email"
                dataGridEntityPlural="Emails"
                enableOauth={oauthEmailEnabled}
            />
        </>,
    );
}

const engagementMethodsTableContainer: HTMLElement | null = document.getElementById('EngagementMethodsTable');

if (engagementMethodsTableContainer) {
    const root = createRoot(engagementMethodsTableContainer);
    root.render(
        <>
            <h4 className="datatable-settings-header">Engagement Methods</h4>
            <Banner />
            <EngagementMethodsTable
                searchFilterPlaceholder="Search Engagement Method or Processing Ground"
                dataGridUniqueKey="EngagementMethods"
                dataGridEntitySingular="Engagement Method"
                dataGridEntityPlural="Engagement Methods"
            />
        </>,
    );
}

const tasksTableContainer: HTMLElement | null = document.getElementById('TasksTable');

if (tasksTableContainer) {
    const customProperties: any = JSON.parse(
        String((document.getElementById('custom-properties') as HTMLInputElement).value),
    );
    const root = createRoot(tasksTableContainer);
    root.render(
        <>
            <h1 className="page-header">Tasks</h1>
            <Banner />
            <TasksTable
                searchFilterPlaceholder="Search Task Name, Contact, or Organisation"
                dataGridUniqueKey="Tasks"
                dataGridEntitySingular="Task"
                dataGridEntityPlural="Tasks"
                customProperties={customProperties}
            />
        </>,
    );
}

const mainDealsTableContainer: HTMLElement | null = document.getElementById('MainDealsTable');

if (mainDealsTableContainer) {
    const customProperties: any = JSON.parse(
        String((document.getElementById('custom-properties') as HTMLInputElement).value),
    );
    const root = createRoot(mainDealsTableContainer);
    root.render(
        <>
            <h1 className={'page-header'}>Deals</h1>
            <Banner />
            <DealsTable
                searchFilterPlaceholder="Search Deal Name, Contact, or Organisation"
                dataGridUniqueKey="MainDeals"
                dataGridEntitySingular="Deal"
                dataGridEntityPlural="Deals"
                customProperties={customProperties}
            />
        </>,
    );
}

const salesTableContainer: HTMLElement | null = document.getElementById('SalesTable');

if (salesTableContainer) {
    const root = createRoot(salesTableContainer);
    root.render(
        <>
            <h1 className="page-header">Sales</h1>
            <Banner />
            <SalesTable
                searchFilterPlaceholder="Search Invoice Number, Quote Number, Deal, Contact, or Organisation"
                dataGridUniqueKey="Sales"
                dataGridEntitySingular="Sale"
                dataGridEntityPlural="Sales"
            />
        </>,
    );
}

const quotesTableContainer: HTMLElement | null = document.getElementById('QuotesTable');

if (quotesTableContainer) {
    const root = createRoot(quotesTableContainer);
    root.render(
        <>
            <h1 className="page-header">Quotes</h1>
            <Banner />
            <QuotesTable
                searchFilterPlaceholder="Search Quote Number, Deal, Contact, Organisation"
                dataGridUniqueKey="id"
                dataGridEntitySingular="Quote"
                dataGridEntityPlural="Quotes"
            />
        </>,
    );
}

const paymentsTableContainer: HTMLElement | null = document.getElementById('PaymentsTable');

if (paymentsTableContainer) {
    const root = createRoot(paymentsTableContainer);
    root.render(
        <>
            <h1 className="page-header">Payments</h1>
            <Banner />
            <PaymentsTable
                searchFilterPlaceholder="Search Invoice Number, or Organisation"
                dataGridUniqueKey="Payments"
                dataGridEntitySingular="Payment"
                dataGridEntityPlural="Payments"
            />
        </>,
    );
}

const glossaryTableContainer: HTMLElement | null = document.getElementById('GlossaryTable');

if (glossaryTableContainer) {
    const root = createRoot(glossaryTableContainer);
    root.render(
        <>
            <h1 className="page-header">Glossary</h1>
            <Banner />
            <GlossaryTable
                searchFilterPlaceholder="Search by Item, Abbreviation, Tags or Description"
                dataGridUniqueKey="Glossary"
                dataGridEntitySingular="Glossary Item"
                dataGridEntityPlural="Glossary Items"
            />
        </>,
    );
}

const clientTagsContainer: HTMLElement | null = document.getElementById('ClientTagsTable');

if (clientTagsContainer) {
    const root = createRoot(clientTagsContainer);
    root.render(
        <>
            <h1 className="page-header">Tags</h1>
            <Banner />
            <ClientTagsTable
                searchFilterPlaceholder="Search by Description"
                dataGridUniqueKey="Tags"
                dataGridEntitySingular="Tag"
                dataGridEntityPlural="Tags"
            />
        </>,
    );
}

const templatesTableContainer: HTMLElement | null = document.getElementById('TemplatesTable');

if (templatesTableContainer) {
    const root = createRoot(templatesTableContainer);
    root.render(
        <>
            <h1 className={'page-header'}>Templates</h1>
            <Banner />
            <TemplatesTable
                searchFilterPlaceholder="Search Templates"
                dataGridUniqueKey="Templates"
                dataGridEntitySingular="Template"
                dataGridEntityPlural="Templates"
            />
        </>,
    );
}
