import {
    AsyncDropDownPaginated,
    LogLevel,
    Modal,
    ModalType,
    OptionTypeBase,
    OptionTypeBaseUserFormatter,
    PendingButton,
    SearchQuery,
    showBanner,
    SortOrder,
    User,
} from '@sprint/sprint-react-components';
import { Editor } from '@tinymce/tinymce-react';
import _ from 'lodash';
import React, { BaseSyntheticEvent, FunctionComponent, useContext, useEffect, useRef, useState } from 'react';
import { Button, Form, FormGroup } from 'react-bootstrap';
import { FieldValues, useForm } from 'react-hook-form';
import { ClientGroupsRequest } from '../../Api/ClientGroupsRequest';
import { TemplatesRequest } from '../../Api/TemplatesRequest';
import { UserTypeRequest } from '../../Api/UserTypeRequest';
import { RepositoryFactoryContext, UserContext } from '../../index';
import ClientGroup from '../../Models/ClientGroup';
import { ClientGroupType } from '../../Models/Enums';
import Template from '../../Models/Template';
import UserType from '../../Models/UserType';
import './TemplatesTable.scss';

interface Props {
    shown: boolean;
    onClose: () => void;
    onSuccess: (event: any) => Promise<boolean>;
    uniqueKey: string;
    setUpdateLimits?: (value: boolean) => void;
    editTemplate?: Template;
}

type FormData = {
    id?: number;
    client_id: number;
    template_title: string;
    template_subject: string;
    template_content: string;
    shared: boolean;
    owned_by_id: number;
    version: number;
    client_group_id?: number;
};

const JustMeSelectOption: OptionTypeBase = {
    label: 'Just Me',
    value: false,
};

const EveryoneSelectOption: OptionTypeBase = {
    label: 'Everyone',
    value: true,
};

const TemplatesAddEditModal: FunctionComponent<Props> = (props: Props) => {
    const templatesRepository = useContext(RepositoryFactoryContext).getApiRepository(new TemplatesRequest());
    const usersRepository = useContext(RepositoryFactoryContext).getApiRepository(new UserTypeRequest());
    const clientGroupsRepository = useContext(RepositoryFactoryContext).getApiRepository(
        new ClientGroupsRequest(ClientGroupType.EMAIL_TEMPLATES),
    );
    const user: User = useContext(UserContext);

    const focusRef = useRef<HTMLInputElement>(null);
    const formRef = useRef<HTMLFormElement | null>(null);
    const editorRef = useRef<Editor | undefined>(undefined);

    const [shown, setShown] = useState<boolean>(false);
    const [ownedBy, setOwnedBy] = useState<OptionTypeBase | null>();
    const [shared, setShared] = useState<OptionTypeBase>(JustMeSelectOption);
    const [clientGroup, setClientGroup] = useState<OptionTypeBase | null>();

    const [initialTemplateContent, setInitialTemplateContent] = useState<string>('');

    // Dropdown Loading
    const [submitting, setSubmitting] = useState<boolean>(false);

    const {
        register,
        handleSubmit,
        setValue,
        clearErrors,
        reset,
        watch,
        formState: { errors },
    } = useForm<FormData>({
        defaultValues: {
            client_id: Number(user.id),
            version: 2,
            shared: false,
        },
        mode: 'all',
    });

    const currentFormData = watch();

    useEffect(() => {
        register('owned_by_id', { required: 'This field is required.' });
        register('template_content', { required: 'This field is required.' });
    }, []);

    useEffect(() => {
        if (
            submitting &&
            (errors.template_title || errors.template_subject || errors.template_content || errors.owned_by_id)
        ) {
            setSubmitting(false);
        }
    }, [
        errors.template_title,
        errors.template_subject,
        errors.template_content,
        errors.owned_by_id,
        errors.template_content,
    ]);

    useEffect(() => {
        setShown(props.shown);
        if (props.shown) {
            if (props.editTemplate) {
                setValue('id', props.editTemplate.id!);
                setValue('client_id', props.editTemplate.client_id);
                setValue('template_title', props.editTemplate.name);
                setValue('template_subject', props.editTemplate.template_subject);

                const templateContent = props.editTemplate.template_content;
                setValue('template_content', templateContent);
                setInitialTemplateContent(templateContent);

                const shared = props.editTemplate.shared;
                setValue('shared', shared);
                if (shared) {
                    setShared(EveryoneSelectOption);
                } else {
                    setShared(JustMeSelectOption);
                }

                setValue('owned_by_id', props.editTemplate.owned_by.id);
                setOwnedBy(OptionTypeBaseUserFormatter(props.editTemplate.owned_by));

                setValue('version', props.editTemplate.version);

                if (props.editTemplate.client_group) {
                    setValue('client_group_id', props.editTemplate.client_group.id);
                    setClientGroup({
                        value: props.editTemplate.client_group.id!,
                        label: props.editTemplate.client_group.name,
                    });
                }
            }

            if (focusRef.current) {
                focusRef.current.focus();
                focusRef.current.selectionStart = focusRef.current.value.length;
                focusRef.current.selectionEnd = focusRef.current.value.length;
            }
        }
    }, [props.shown]);

    const handleAddRow = async (data: FieldValues): Promise<boolean> => {
        setSubmitting(true);
        return templatesRepository
            .create(data)
            .then(props.onSuccess)
            .then(async (success) => {
                setSubmitting(false);
                const action = props.editTemplate ? 'updated' : 'created';
                showBanner({
                    message: `Template ${action} successfully`,
                    level: LogLevel.SUCCESS,
                });
                if (props.setUpdateLimits) {
                    props.setUpdateLimits(true);
                }
                reset();
                return success;
            })
            .catch((err) => {
                setSubmitting(false);
                const action = props.editTemplate ? 'update' : 'create';
                showBanner(
                    {
                        message: `Failed to ${action} Template - ` + (err?.message ?? err),
                        level: LogLevel.ERROR,
                    },
                    props.uniqueKey,
                );
                return false;
            });
    };

    const onSubmitForm = async (data: FieldValues, event?: BaseSyntheticEvent<unknown, any, any>) => {
        event?.preventDefault();
        setSubmitting(true);
        if (await handleAddRow(data)) {
            props.onClose();
        }
        setSubmitting(false);
    };

    const loadUsers = (filter?: string, page?: number): Promise<{ value: number; label: JSX.Element }[]> => {
        // Fetch all users for client account
        const query = new SearchQuery(page ?? 1, 100, 'id', SortOrder.ASC, filter);
        return usersRepository
            .search(query)
            .then((results) => {
                return _.map(
                    _.filter(results.results as UserType[], (user: UserType) => {
                        return _.includes(user?.name?.toLowerCase(), filter?.toLowerCase());
                    }),
                    (user: UserType) => {
                        return OptionTypeBaseUserFormatter(user);
                    },
                );
            })
            .catch((err: any) => {
                showBanner({
                    message: 'Failed to get users - ' + (err?.message ?? err),
                });
                return [];
            });
    };

    const getClientGroups = async (filter: string) => {
        const query = new SearchQuery(1, 1000);
        if (filter.length > 0) {
            query.setFilter(filter);
        }
        return clientGroupsRepository
            .search(query)
            .then((results: any) => {
                return results.results.map((result: ClientGroup) => {
                    return { value: result.id, label: result.name };
                });
            })
            .catch((err: any) => {
                return null;
            });
    };

    const closeAddEditModal = () => {
        reset();
        props.onClose();
    };

    return (
        <>
            <Modal
                className="views-modal templates-add-edit-modal"
                title={'Create a Template'}
                type={ModalType.INFO}
                isOpen={shown}
                close={closeAddEditModal}
                footerOverride={
                    <>
                        <Button variant="default" onClick={closeAddEditModal}>
                            Cancel
                        </Button>
                        <div
                            style={{
                                opacity: 1,
                                pointerEvents: 'auto',
                            }}
                        >
                            <PendingButton
                                type="submit"
                                form="template_form"
                                pending={submitting}
                                onClick={() => setSubmitting(true)}
                            >
                                Save
                            </PendingButton>
                        </div>
                    </>
                }
            >
                <Form id="template_form" onSubmit={handleSubmit(onSubmitForm)} ref={formRef}>
                    <Form.Row className="row">
                        <div className="col-md-6">
                            <Form.Group className={errors.template_title ? 'has-error' : ''}>
                                <Form.Label>Name</Form.Label>
                                <Form.Control
                                    autoComplete="off"
                                    {...register('template_title', { required: 'This field is required.' })}
                                />
                                {errors.template_title && (
                                    <span className="help-block">{errors.template_title.message}</span>
                                )}
                            </Form.Group>
                        </div>
                        <div className="col-md-6">
                            <Form.Group className={errors.shared ? 'has-error' : ''}>
                                <Form.Label>Shared With</Form.Label>
                                <AsyncDropDownPaginated
                                    id={'shared'}
                                    value={shared}
                                    isInvalid={false}
                                    isClearable={false}
                                    menuPlacement="auto"
                                    menuPosition="fixed"
                                    menuPortalTarget={document.body}
                                    classNamePrefix={'shared-dropdown'}
                                    onChange={(selected: OptionTypeBase) => {
                                        setShared({
                                            label: selected.label,
                                            value: selected.value,
                                        });
                                        setValue('shared', selected?.value ?? false);
                                    }}
                                    loadOptions={async () => {
                                        return {
                                            options: [JustMeSelectOption, EveryoneSelectOption],
                                            hasMore: false,
                                        };
                                    }}
                                />
                            </Form.Group>
                        </div>
                    </Form.Row>
                    <Form.Row className="row">
                        <div className="col-md-6">
                            <Form.Group className={errors.template_subject ? 'has-error' : ''}>
                                <Form.Label>Subject</Form.Label>
                                <Form.Control
                                    autoComplete="off"
                                    {...register('template_subject', { required: 'This field is required.' })}
                                />
                                {errors.template_subject && (
                                    <span className="help-block">{errors.template_subject.message}</span>
                                )}
                            </Form.Group>
                        </div>
                        <div className="col-md-6">
                            <Form.Group className={errors.owned_by_id ? 'has-error' : ''}>
                                <Form.Label>Owned By</Form.Label>
                                <AsyncDropDownPaginated
                                    id={'owned_by_id'}
                                    value={ownedBy}
                                    isInvalid={false}
                                    isClearable={false}
                                    menuPlacement="auto"
                                    menuPosition="fixed"
                                    menuPortalTarget={document.body}
                                    classNamePrefix={'owned-by-dropdown'}
                                    onChange={(selected: OptionTypeBase) => {
                                        setOwnedBy({
                                            label: selected.label,
                                            value: selected.value,
                                        });
                                        setValue('owned_by_id', selected?.value ?? '');
                                        clearErrors('owned_by_id');
                                    }}
                                    loadOptions={async (filter: string, _loadedOptions, { page }) => {
                                        const res = await loadUsers(filter, page);
                                        return {
                                            options: res,
                                            hasMore: false,
                                            additional: {
                                                page: page + 1,
                                            },
                                        };
                                    }}
                                />
                                {errors.owned_by_id && <span className="help-block">{errors.owned_by_id.message}</span>}
                            </Form.Group>
                        </div>
                    </Form.Row>
                    <Form.Row className="row">
                        <div className="col-md-6">
                            <Form.Group className={errors.client_group_id ? 'has-error' : ''}>
                                <Form.Label>Folder</Form.Label>
                                <AsyncDropDownPaginated
                                    id={'client_group_id'}
                                    value={clientGroup}
                                    isInvalid={false}
                                    isClearable={true}
                                    menuPlacement="auto"
                                    menuPosition="fixed"
                                    menuPortalTarget={document.body}
                                    classNamePrefix={'client-group-id-dropdown'}
                                    onChange={(selected: OptionTypeBase) => {
                                        setClientGroup(
                                            selected
                                                ? {
                                                      label: selected.label,
                                                      value: selected.value,
                                                  }
                                                : null,
                                        );
                                        setValue('client_group_id', selected?.value ?? '');
                                        clearErrors('client_group_id');
                                    }}
                                    loadOptions={async (filter: string, _loadedOptions, { page }) => {
                                        const res = await getClientGroups(filter);
                                        return {
                                            options: res,
                                            hasMore: false,
                                            additional: {
                                                page: page + 1,
                                            },
                                        };
                                    }}
                                />
                                {errors.client_group_id && (
                                    <span className="help-block">{errors.client_group_id.message}</span>
                                )}
                            </Form.Group>
                        </div>
                    </Form.Row>

                    <Form.Row className="row" style={{ marginTop: '15px' }}>
                        <div className="col-md-12">
                            <FormGroup className={errors.template_content ? 'has-error' : ''}>
                                <Editor
                                    tinymceScriptSrc="/assets/tinymce/tinymce/tinymce.min.js"
                                    licenseKey="gpl"
                                    onInit={(_evt, editor) => (editorRef.current = editor as unknown as Editor)}
                                    initialValue={initialTemplateContent}
                                    init={{
                                        width: '100%',
                                        height: '300',
                                        script_url: '/assets/tinymce/tinymce/tinymce.min.js',
                                        menubar: false,
                                        statusbar: false,
                                        relative_urls: false,
                                        convert_urls: false,
                                        force_p_newlines: true,
                                        toolbar:
                                            'bold italic underline | fontfamily fontsize | link table code fullscreen | clientcampusmergefieldsnr clientcampusfiles clientcampusimages',
                                        plugins:
                                            'link image table code fullscreen clientcampusmergefieldsnr clientcampusfiles clientcampusimages',
                                        fontsize_formats: '8pt 10pt 11pt 12pt 14pt 18pt 24pt 36pt',
                                        font_formats:
                                            'Andale Mono=andale mono,times;' +
                                            'Arial=arial,helvetica,sans-serif;' +
                                            'Arial Black=arial black,avant garde;' +
                                            'Book Antiqua=book antiqua,palatino;' +
                                            'Comic Sans MS=comic sans ms,sans-serif;' +
                                            'Courier New=courier new,courier;' +
                                            'Georgia=georgia,palatino;' +
                                            'Helvetica=helvetica;' +
                                            'Impact=impact,chicago;' +
                                            'Open Sans=open sans,arial;' +
                                            'Symbol=symbol;' +
                                            'Tahoma=tahoma,arial,helvetica,sans-serif;' +
                                            'Terminal=terminal,monaco;' +
                                            'Times New Roman=times new roman,times;' +
                                            'Trebuchet MS=trebuchet ms,geneva;' +
                                            'Verdana=verdana,geneva',
                                        setup: function (editor) {
                                            editor.on('change keyup', function () {
                                                setValue('template_content', editor.getContent());
                                                clearErrors('template_content');
                                            });
                                        },
                                    }}
                                />
                                {errors.template_content && (
                                    <span className="help-block">{errors.template_content.message}</span>
                                )}
                            </FormGroup>
                        </div>
                    </Form.Row>
                </Form>
            </Modal>
        </>
    );
};

export default TemplatesAddEditModal;
