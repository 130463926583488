import ClientTagPill from '@client/CommonComponents/ClientTags/ClientTagPill';
import TagsEditDropdown from '@client/CommonComponents/ClientTags/TagsEditDropdown';
import { UserPermissionsContext } from '@client/Context/UserPermissions';
import {
    AppEvent,
    AsyncDropDownPaginated,
    EventBusInstance,
    LogLevel,
    lookupValidator,
    OptionTypeBase,
    OptionTypeBaseUserFormatter,
    PendingButton,
    SearchQuery,
    showBanner,
    Sisp,
    SortOrder,
} from '@sprint/sprint-react-components';
import _ from 'lodash';
import React, { BaseSyntheticEvent, FunctionComponent, useContext, useEffect, useState } from 'react';
import { Accordion, Button, Form } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import { FieldValues, useForm } from 'react-hook-form';
import { RepositoryFactoryContext } from '../..';
import HiddenEmailAddress from '../../../../CommonComponents/HiddenEmailAddress/HiddenEmailAddress';
import IEngagementMethod from '../../../../Entities/EngagementMethod/Interfaces/IEngagementMethod';
import { ContactsRequest } from '../../Api/ContactsRequest';
import { EngagementMethodsRequest } from '../../Api/EngagementMethodsRequest';
import { UserTypeRequest } from '../../Api/UserTypeRequest';
import { KnowledgeBaseUrlKey, KnowledgeBaseUrls } from '../../HelperFunctions/KnowledgeBaseUrls';
import Contact from '../../Models/Contact';
import UserType from '../../Models/UserType';
import CustomPropertyForm, { AvailablePropertyTypes } from '../CustomProperties/CustomPropertyForm';

interface Props {
    uniqueKey: string;
    onSuccess: (event: any) => Promise<boolean>;
}

type FormData = {
    id: number;
    organisation_id: number;
    title?: string;
    firstname: string;
    surname?: string;
    jobrole: string;
    email?: string;
    telephone: string;
    owned_by_id: any;
    engagement_method_id?: any;
    engagement_date?: string;
    education_data_package?: string;
    tag_ids?: number[];
};

const ContactsEditSisp: FunctionComponent<Props> = (props: Props) => {
    const {
        register,
        handleSubmit,
        setValue,
        watch,
        reset,
        setError,
        formState: { errors, isSubmitting },
    } = useForm<FormData>({ mode: 'all' });

    const permissions = useContext(UserPermissionsContext);
    const customTagsEnabled = permissions.contactsCustomTags.isEnabled;
    const obfuscateNonClaimedContactEmails = permissions.obfuscateNonClaimedContactEmails.isEnabled;

    const [shown, setShown] = useState<boolean>(false);
    const [submitting, setSubmitting] = useState<boolean>(false);

    // Repositories
    const contactsRepository = useContext(RepositoryFactoryContext).getApiRepository(new ContactsRequest());
    const engagementMethodRepository = useContext(RepositoryFactoryContext).getApiRepository(
        new EngagementMethodsRequest(),
    );
    const userRepository = useContext(RepositoryFactoryContext).getApiRepository(new UserTypeRequest());

    // Fields
    const newEmail = watch('email');
    // Form state
    const [contact, setContact] = useState<Contact>();

    // Teacher Emails
    const [teacherEmail, setTeacherEmail] = useState<string>('');
    const [claiming, setClaiming] = useState<boolean>(false);

    const [ownedBy, setOwnedBy] = useState<OptionTypeBase | null>();
    const [engagementMethod, setEngagementMethod] = useState<OptionTypeBase | null>();

    const engagementDateValue = watch('engagement_date');
    const engagementDate = engagementDateValue ? new Date(engagementDateValue) : undefined;

    const [customPropertyValues, setCustomPropertyValues] = useState<any>();
    const customProperties: any = JSON.parse(
        String((document.getElementById('custom-properties') as HTMLInputElement).value),
    );

    // State: Tags
    const [tags, setTags] = useState<{ value: number; label: any }[]>();

    useEffect(() => {
        EventBusInstance.subscribe('show-hoverover-component', (event: AppEvent<Contact>) => {
            // Set the form state and values from the event object.
            // If another sisp open event was triggered, we close this SISP and reset the form.
            if (event.target !== props.uniqueKey) {
                resetForm();
                setShown(false);
                return;
            }

            setContact(event.message);
            setClaiming(false); // Reset claiming state whenever opening
            setValue('id', event.message.id);
            setValue('organisation_id', event.message.organisation.id);
            setValue('education_data_package', event.message.education_data_package ?? '');
            setValue('firstname', event.message.first_name ?? '');
            setValue('surname', event.message.last_name ?? '');
            setValue('title', event.message.title ?? '');
            setValue('jobrole', event.message.job_role ?? '');
            setValue('telephone', event.message.telephone ?? '');

            if (event.message.education_data_package && event.message.email && !event.message.email_claimed) {
                setTeacherEmail(event.message.email);
            } else {
                setTeacherEmail('');
                setValue('email', event.message.email ?? '');
            }

            if (event.message.engagement_method) {
                // Visual value for dropdown
                setEngagementMethod({
                    value: event.message.engagement_method?.id ?? 0,
                    label:
                        event.message.engagement_method?.name +
                        ' - ' +
                        event.message.engagement_method?.processing_ground,
                    iso: event.message.engagement_method?.iso,
                });
                // actual form value
                setValue('engagement_method_id', event.message.engagement_method?.id ?? '');
                setValue('engagement_date', event.message.engagement_method?.engagement_date ?? '');
            } else {
                setEngagementMethod(null);
                setValue('engagement_method_id', undefined);
                setValue('engagement_date', new Date().toISOString());
            }

            if (event.message.owned_by) {
                // Visual value for dropdown
                setOwnedBy(OptionTypeBaseUserFormatter(event.message.owned_by));
                // actual form value
                setValue('owned_by_id', event.message.owned_by?.id ?? '');
            } else {
                setOwnedBy(null);
                setValue('owned_by_id', event.message.owned_by?.id ?? null);
            }

            if (customTagsEnabled) {
                setTags(
                    event.message.tags?.map((t) => {
                        return {
                            value: t.id ?? 0,
                            label: <ClientTagPill tag={t} />,
                        };
                    }) ?? [],
                );
            }

            // Set custom properties values
            setCustomPropertyValues(event.message.custom_properties);

            setSubmitting(false);
            setShown(true);
        });
    }, [shown]);

    const handleEditRow = async (
        data: FieldValues,
        event?: BaseSyntheticEvent<unknown, any, any>,
    ): Promise<boolean> => {
        return contactsRepository
            .update(data)
            .then((results: any) => {
                props.onSuccess([results.data]);
                showBanner({
                    message: 'Contact updated successfully',
                    level: LogLevel.SUCCESS,
                });
                const buttonClicked = (event?.nativeEvent as SubmitEvent | undefined)?.submitter;
                if (buttonClicked?.dataset.redirectTo) {
                    window.location.href = `/subscribers/contacts/view/${results.data.id}`;
                }
                return Promise.resolve(true);
            })
            .catch((err) => {
                showBanner({
                    message: 'Failed to update contact - ' + (err?.message ?? err),
                    level: LogLevel.ERROR,
                });
                return Promise.resolve(false);
            });
    };

    const onSubmitForm = async (data: FieldValues, event?: BaseSyntheticEvent<unknown, any, any>) => {
        event?.preventDefault();
        setSubmitting(true);
        if ((await validate()) && (await handleEditRow(data, event))) {
            setShown(false);
            resetForm();
        } else {
            setSubmitting(false);
        }
    };

    const resetForm = () => {
        setOwnedBy(null);
        setEngagementMethod(null);
        setCustomPropertyValues(null);
        setSubmitting(false);
        reset();
    };

    const loadUsers = (filter?: string, page?: number): Promise<{ value: number; label: JSX.Element }[]> => {
        // Fetch all users for client account
        const query = new SearchQuery(page ?? 1, 100, 'id', SortOrder.ASC, filter);
        return userRepository
            .search(query)
            .then((results) => {
                return _.map(
                    _.filter(results.results as UserType[], (user: UserType) => {
                        return _.includes(user?.name?.toLowerCase(), filter?.toLowerCase());
                    }),
                    (user: UserType) => {
                        return OptionTypeBaseUserFormatter(user);
                    },
                );
            })
            .catch((err: any) => {
                showBanner({
                    message: 'Failed to get users - ' + (err?.message ?? err),
                });
                return [];
            });
    };

    const loadEngagementMethods = (
        filter?: string,
        page?: number,
    ): Promise<{ value: number; label: string; iso: string }[]> => {
        const query = new SearchQuery(page ?? 1, 100, 'id', SortOrder.ASC, filter);
        return engagementMethodRepository
            .search(query)
            .then((results) => {
                const res = _.map(results.results as any, (engagementMethod: IEngagementMethod) => {
                    return {
                        value: engagementMethod.id ?? 0,
                        label: engagementMethod.engagement_method + ' - ' + engagementMethod.processing_ground,
                        iso: engagementMethod.iso,
                    };
                });
                return res;
            })
            .catch((err) => {
                showBanner({
                    message: 'Failed to get Engagement Methods - ' + (err?.message ?? err),
                    level: LogLevel.ERROR,
                });
                return [];
            });
    };

    const checkEmailIsUnique = async (email: string): Promise<boolean> => {
        return contactsRepository
            .post_action('check_email', undefined, { email: email })
            .then((results: any) => {
                // if the email hasn't changed, assume it is unique as only this contact will have it.
                if (contact?.email == email) return true;
                return results.data.result;
            })
            .catch((err) => {
                return false;
            });
    };

    const validate = async (): Promise<boolean> => {
        let emailValid = true;

        const emailValidator = lookupValidator('email', 'text');
        emailValid = emailValidator.validate(newEmail ?? '');

        if (emailValid && contact?.email != newEmail) {
            emailValid = await checkEmailIsUnique(newEmail ?? '');
            if (!emailValid) {
                setError(
                    'email',
                    { type: 'validate', message: 'Email is already used by another contact' },
                    { shouldFocus: true },
                );
            }
        }

        const newValidationState = {
            email: emailValid,
        };
        return _.every(newValidationState);
    };

    const setCustomPropertyValue = (key: any, value: any) => {
        setValue(key, value);
    };

    return (
        <Sisp
            isOpen={shown}
            onCancel={() => {
                resetForm();
                setShown(false);
            }}
            validate={validate}
            footerOverride={
                <>
                    <PendingButton
                        pending={submitting}
                        type="submit"
                        form="contact_edit_form"
                        onClick={() => setSubmitting(false)}
                    >
                        Save
                    </PendingButton>
                    <PendingButton
                        redirect={true}
                        pending={submitting}
                        onClick={() => setSubmitting(false)}
                        type="submit"
                        form="contact_edit_form"
                        variant="default"
                    >
                        Save & View
                    </PendingButton>
                </>
            }
        >
            <h4>Edit {contact?.full_name}</h4>
            <Form id="contact_edit_form" onSubmit={handleSubmit(onSubmitForm)}>
                <Form.Group>
                    <Form.Control type="hidden" {...register('id')} />
                </Form.Group>
                <Form.Group>
                    <Form.Control type="hidden" {...register('organisation_id')} />
                </Form.Group>
                <Form.Group>
                    <Form.Control type="hidden" {...register('education_data_package')} />
                </Form.Group>
                <Form.Group>
                    <Form.Label>First Name</Form.Label>
                    <Form.Control autoComplete="off" {...register('firstname')} />
                </Form.Group>
                <Accordion>
                    <Form.Group>
                        <Form.Row className="row">
                            <div className="col-md-6">
                                <Form.Label className="pull-left">Last Name</Form.Label>
                            </div>
                            <div className="col-md-6">
                                <label className="pull-right">
                                    <Accordion.Toggle as={Button} variant="link" eventKey="0">
                                        Add Title
                                    </Accordion.Toggle>
                                </label>
                            </div>
                        </Form.Row>
                        <Form.Control autoComplete="off" {...register('surname')} />
                    </Form.Group>
                    <Accordion.Collapse eventKey="0">
                        <Form.Group>
                            <Form.Label>Title</Form.Label>
                            <Form.Control autoComplete="off" {...register('title')} />
                        </Form.Group>
                    </Accordion.Collapse>
                </Accordion>
                <Form.Group className={errors.jobrole ? 'has-error' : ''}>
                    <Form.Label>Job Role</Form.Label>
                    <Form.Control {...register('jobrole', { required: 'This field is required.' })} />
                    {errors.jobrole && <span className="help-block">{errors.jobrole.message}</span>}
                </Form.Group>

                <Form.Group>
                    <Form.Label>Owned By</Form.Label>
                    <AsyncDropDownPaginated
                        id={'owned_by_id'}
                        value={ownedBy}
                        isInvalid={false}
                        isClearable={true}
                        menuPlacement="auto"
                        menuPosition="fixed"
                        menuPortalTarget={document.body}
                        classNamePrefix={'owned-by-dropdown'}
                        onChange={(selected: OptionTypeBase) => {
                            setOwnedBy(selected);
                            setValue('owned_by_id', selected?.value ?? '');
                        }}
                        loadOptions={async (filter: string, _loadedOptions, { page }) => {
                            const res = await loadUsers(filter, page);
                            return {
                                options: res,
                                hasMore: false,
                                additional: {
                                    page: page + 1,
                                },
                            };
                        }}
                    />
                </Form.Group>

                <Form.Group className={errors.email ? 'has-error' : ''}>
                    <Form.Row className="row">
                        <div className="col-md-6">
                            <Form.Label className="pull-left">Email</Form.Label>
                        </div>
                        {teacherEmail && (
                            <div className="col-md-6">
                                <label className="pull-right">
                                    {claiming ? (
                                        <span
                                            onClick={() => {
                                                setClaiming(false);
                                                setValue('email', '');
                                            }}
                                        >
                                            <a>Cancel</a>
                                        </span>
                                    ) : (
                                        <span
                                            onClick={() => {
                                                setClaiming(true);
                                                setValue('email', '');
                                            }}
                                        >
                                            <a>Claim</a>
                                        </span>
                                    )}
                                </label>
                            </div>
                        )}
                    </Form.Row>
                    {teacherEmail && !claiming && obfuscateNonClaimedContactEmails ? (
                        <>
                            <div className="form-control">
                                <HiddenEmailAddress template={teacherEmail} />
                            </div>
                            <span className="help-block">
                                Why is this email obfuscated?{' '}
                                <a target="_blank" href={KnowledgeBaseUrls.get(KnowledgeBaseUrlKey.OBFUSCATED_EMAILS)}>
                                    Learn more
                                </a>
                                .
                            </span>
                        </>
                    ) : (
                        <>
                            <Form.Control
                                autoComplete="off"
                                {...register('email', {
                                    pattern: {
                                        // eslint-disable-next-line max-len
                                        value: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                                        message: 'This email address is not valid.',
                                    },
                                })}
                            />
                            {errors.email && <span className="help-block">{errors.email.message}</span>}
                        </>
                    )}
                </Form.Group>

                <Form.Group>
                    <Form.Label>Telephone</Form.Label>
                    <Form.Control {...register('telephone')} />
                </Form.Group>

                <Form.Group>
                    <Form.Label>Engagement Method</Form.Label>
                    <AsyncDropDownPaginated
                        id={'engagement_method_id'}
                        value={engagementMethod}
                        isInvalid={false}
                        isClearable={true}
                        menuPlacement="top"
                        menuPosition="fixed"
                        menuPortalTarget={document.body}
                        classNamePrefix={'engagement-method-dropdown'}
                        onChange={(selected: OptionTypeBase) => {
                            if (selected) {
                                setEngagementMethod(selected);
                                setValue('engagement_method_id', selected.value);
                            } else {
                                setEngagementMethod(undefined);
                                setValue('engagement_method_id', undefined);
                                setValue('engagement_date', undefined);
                            }
                        }}
                        loadOptions={async (filter: string, _loadedOptions, { page }) => {
                            const res = await loadEngagementMethods(filter, page);
                            return {
                                options: res,
                                hasMore: false,
                                additional: {
                                    page: page + 1,
                                },
                            };
                        }}
                    />
                </Form.Group>
                {engagementMethod && (
                    <Form.Group>
                        <Form.Label>Engagement Date</Form.Label>
                        <div style={{ justifyContent: 'flex-start', width: '100%' }}>
                            <DatePicker
                                {...register('engagement_date')}
                                className="dg-sisp-date-picker wide"
                                placeholderText="Engagement Date"
                                selected={engagementDate}
                                onChange={(date: any) => setValue('engagement_date', date)}
                                dateFormat="EEE, d MMM yyyy"
                                portalId="root-react-datepicker-portal"
                            />
                        </div>
                    </Form.Group>
                )}

                {customTagsEnabled && (
                    <TagsEditDropdown
                        onChange={(tags) => {
                            setTags(tags);
                            setValue(
                                'tag_ids',
                                tags.map((v) => v.value),
                            );
                        }}
                        existingTags={tags}
                    />
                )}

                <CustomPropertyForm
                    propertyType={AvailablePropertyTypes.contacts}
                    customProperties={customProperties}
                    customPropertyValues={customPropertyValues}
                    updateFormPropertyState={setCustomPropertyValues}
                    setPropertyValue={setCustomPropertyValue}
                />
            </Form>
        </Sisp>
    );
};

export default ContactsEditSisp;
