import { ClientGroupsRequest } from '@client/Applications/DataGrids/Api/ClientGroupsRequest';
import ClientGroup from '@client/Applications/DataGrids/Models/ClientGroup';
import { ClientGroupType } from '@client/Applications/DataGrids/Models/Enums';
import { UserPermissionsContext } from '@client/Context/UserPermissions';
import { faCheck, faCircle, faStar, faThumbtack } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    AppEvent,
    Banner,
    EntityTarget,
    EventBusInstance,
    ExtendedColumn,
    HoveroverButton,
    LogLevel,
    Modal,
    ModalType,
    OptionTypeBase,
    OptionTypeBaseUserFormatter,
    ProfileAddEditModalType,
    SavedProfile,
    SavedViewsGrid,
    SearchQuery,
    SelectColumn,
    SortOrder,
    User,
    UserFormatter,
    filter as filterType,
    showBanner,
} from '@sprint/sprint-react-components';
import _ from 'lodash';
import React, { FunctionComponent, useContext, useEffect, useRef, useState } from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { RenderCellProps } from 'react-data-grid';
import DataGridRequest from '../Api/DataGridRequest';
import { UserTypeRequest } from '../Api/UserTypeRequest';
import UserType from '../Models/UserType';
import { RepositoryFactoryContext, UserContext } from '../index';
import { SavedInboundView } from './SavedInboundView';

export enum SavedViewType {
    MARKETING_LIST,
    SAVED_SEARCH,
}

export enum EduDataListsColumnKey {
    ID,
    NAME,
    SIZE,
    DESCRIPTION,
    CREATED_BY,
    LAST_UPDATED,
    SHARED,
    PINNED,
    STARRED,
}

interface Props {
    title: string;
    modalShown: boolean;
    viewFilter: string;
    viewsTarget: EntityTarget;
    getViews: () => void;
    onUpdateView: (view: SavedInboundView) => Promise<boolean>;
    onViewSelected: (view: SavedInboundView) => void;
    onViewFilter: (filter: string) => void;
    onClose: () => void;
    onTogglePinned: (profile: SavedProfile) => void;
    onRowsDeleted: () => any;
    activeViewStorageKey: string;
    request: DataGridRequest<any>;
    savedViewType: SavedViewType;

    entityTypeSingular: string;
    entityTypePlural: string;

    groupType?: ClientGroupType;
}

const ViewsModal: FunctionComponent<Props> = (props: Props) => {
    const savedViewsRepository = useContext(RepositoryFactoryContext).getApiRepository<SavedInboundView>(props.request);
    const userRepository = useContext(RepositoryFactoryContext).getApiRepository(new UserTypeRequest());
    const customPropertiesClientGroupsRepository = useContext(RepositoryFactoryContext).getApiRepository(
        new ClientGroupsRequest(props.groupType ?? ''),
    );
    const userPermissions = useContext(UserPermissionsContext);

    const user: User = useContext(UserContext);

    const [isLoading, setIsLoading] = useState<boolean>(false);

    const Columns: Record<EduDataListsColumnKey, ExtendedColumn> = {
        [EduDataListsColumnKey.ID]: {
            key: 'id',
            name: 'ID',
            sortable: false,
        },
        [EduDataListsColumnKey.NAME]: {
            key: 'name',
            sortKey: 'list_name',
            name: `${props.entityTypeSingular} Name`,
            sortable: true,
            frozen: true,
            renderCell: (formatterProps: RenderCellProps<any>) => {
                const listName = _.get(formatterProps.row, formatterProps.column.key);
                const id = formatterProps.row.id;

                const handleClick = () => {
                    EventBusInstance.publish({
                        topic: 'update-datagrid-rows',
                        message: formatterProps.row,
                        target: 'handle-name-click',
                    });
                };

                return (
                    <>
                        <span onClick={handleClick} className="rdg-link" style={{ cursor: 'pointer' }}>
                            {listName}
                        </span>
                        <HoveroverButton
                            contents="Edit"
                            showHoverover={true}
                            eventBusMessageTarget={'show-edit-modal'}
                            eventBusMessage={formatterProps.row}
                        />
                    </>
                );
            },
            width: '4fr',
        },
        [EduDataListsColumnKey.SIZE]: {
            key: 'list_count',
            name: 'Size',
            description: `${
                props.entityTypeSingular
            } count is calculated when the ${props.entityTypeSingular.toLowerCase()} was last saved.`,
            sortable: true,
            width: '1fr',
        },
        [EduDataListsColumnKey.DESCRIPTION]: {
            key: 'description',
            name: 'Description',
            sortable: true,
            width: '4fr',
        },
        [EduDataListsColumnKey.CREATED_BY]: {
            key: 'createdBy',
            sortKey: 'created_by',
            name: `${props.entityTypeSingular} Owner`,
            renderCell: UserFormatter,
            sortable: true,
            width: '2fr',
        },
        [EduDataListsColumnKey.LAST_UPDATED]: {
            key: 'modified',
            sortKey: 'modified',
            name: 'Last Updated',
            sortable: true,
            width: '2fr',
        },
        [EduDataListsColumnKey.SHARED]: {
            key: 'shared',
            name: 'Shared',
            renderCell: (props: RenderCellProps<any>) => {
                const shared: boolean = props.row.shared ?? false;
                const iconUpdate = { ...props.row, shared: !shared };
                return shared ? (
                    <OverlayTrigger overlay={<Tooltip id="builtin-tooltip">Unshare</Tooltip>} placement="right">
                        <span
                            className="fa-layers fa-fw fa-lg"
                            style={{ fontSize: '1.7em', verticalAlign: '-0.25em' }}
                            onClick={() => handleIconToggle(iconUpdate)}
                        >
                            <FontAwesomeIcon icon={faCircle} style={{ color: '#2FC2AD' }} />
                            <FontAwesomeIcon icon={faCheck} inverse transform="shrink-8" />
                        </span>
                    </OverlayTrigger>
                ) : (
                    <span
                        className="fa-layers fa-fw fa-lg"
                        style={{ fontSize: '1.7em', verticalAlign: '-0.25em' }}
                        onClick={() => handleIconToggle(iconUpdate)}
                    />
                );
            },
            sortable: true,
            width: '1fr',
        },
        [EduDataListsColumnKey.PINNED]: {
            key: 'pinned',
            name: 'Pinned',
            renderCell: (props: RenderCellProps<any>) => {
                const pinned: boolean = props.row.pinned ?? false;
                const iconUpdate = { ...props.row, pinned: !pinned };
                return pinned ? (
                    <OverlayTrigger overlay={<Tooltip id="builtin-tooltip">Unpin</Tooltip>} placement="right">
                        <span
                            className="fa-layers fa-fw fa-lg"
                            style={{ fontSize: '1.7em', verticalAlign: '-0.25em' }}
                            onClick={() => handleIconToggle(iconUpdate)}
                        >
                            <FontAwesomeIcon icon={faCircle} style={{ color: '#09c' }} />
                            <FontAwesomeIcon
                                icon={faThumbtack}
                                style={{ transform: 'rotate(-45deg)', bottom: '-1px', right: '-1px' }}
                                inverse
                                transform="shrink-8"
                            />
                        </span>
                    </OverlayTrigger>
                ) : (
                    <span
                        className="fa-layers fa-fw fa-lg"
                        style={{ fontSize: '1.7em', verticalAlign: '-0.25em' }}
                        onClick={() => handleIconToggle(iconUpdate)}
                    />
                );
            },
            sortable: true,
            width: '1fr',
        },
        [EduDataListsColumnKey.STARRED]: {
            key: 'favourited',
            name: 'Starred',
            renderCell: (props: RenderCellProps<any>) => {
                const favourited: boolean = props.row.favourited ?? false;
                const iconUpdate = { ...props.row, favourited: !favourited };
                return favourited ? (
                    <OverlayTrigger overlay={<Tooltip id="builtin-tooltip">Unstar</Tooltip>} placement="right">
                        <span
                            className="fa-layers fa-fw fa-lg"
                            style={{ fontSize: '1.7em', verticalAlign: '-0.25em' }}
                            onClick={() => handleIconToggle(iconUpdate)}
                        >
                            <FontAwesomeIcon icon={faCircle} style={{ color: '#ffc252' }} />
                            <FontAwesomeIcon icon={faStar} inverse transform="shrink-8" />
                        </span>
                    </OverlayTrigger>
                ) : (
                    <span
                        className="fa-layers fa-fw fa-lg"
                        style={{ fontSize: '1.7em', verticalAlign: '-0.25em' }}
                        onClick={() => handleIconToggle(iconUpdate)}
                    />
                );
            },
            sortable: true,
            width: '1fr',
        },
    };

    const DefaultMarketingListColumns: ExtendedColumn[] = [
        Columns[EduDataListsColumnKey.NAME],
        Columns[EduDataListsColumnKey.SIZE],
        Columns[EduDataListsColumnKey.DESCRIPTION],
        Columns[EduDataListsColumnKey.CREATED_BY],
        Columns[EduDataListsColumnKey.LAST_UPDATED],
        Columns[EduDataListsColumnKey.SHARED],
        Columns[EduDataListsColumnKey.PINNED],
        Columns[EduDataListsColumnKey.STARRED],
    ];

    const DefaultSavedSearchColumns: ExtendedColumn[] = [
        Columns[EduDataListsColumnKey.NAME],
        Columns[EduDataListsColumnKey.CREATED_BY],
        Columns[EduDataListsColumnKey.LAST_UPDATED],
        Columns[EduDataListsColumnKey.SHARED],
        Columns[EduDataListsColumnKey.PINNED],
    ];

    // State: Columns
    const [columns, setColumns] = useState([] as ExtendedColumn[]);
    const [defaultColumns, setDefaultColumns] = useState<ExtendedColumn[]>(
        props.savedViewType == SavedViewType.MARKETING_LIST ? DefaultMarketingListColumns : DefaultSavedSearchColumns,
    );
    // State: DataGrid
    const [reload, setReload] = useState(false);
    // State: Filters
    const [filterState, setFilterState] = useState<Map<string, OptionTypeBase>>(new Map());
    const filtersRef = useRef(filterState);

    // On ComponentDidMount
    useEffect(() => {
        // listen for name click
        EventBusInstance.subscribe('update-datagrid-rows', (event: AppEvent<SavedInboundView>) => {
            if (event.target !== 'handle-name-click') return;
            localStorage.setItem(props.activeViewStorageKey, (event.message.id ?? 0).toString());
            props.onViewSelected(event.message);
        });
    }, []);

    useEffect(() => {
        setColumns([SelectColumn, ...defaultColumns]);
    }, [defaultColumns]);

    useEffect(() => {
        // reset reload if true
        if (reload) setReload(false);
    }, [reload]);

    useEffect(() => {
        filtersRef.current = filterState;
        // filters changed, reload data grid
        setReload(true);
    }, [filterState]);

    const onGetRows = async (query: SearchQuery) => {
        // set extra filters
        if (filtersRef.current.size) {
            const createdBy = filtersRef.current.get('created_by');
            if (createdBy) query.setCreatedBy(createdBy.value);

            const folders = filtersRef.current.get('folders');
            if (folders) query.setExtendedParameters({ folders: folders.value });
        }

        return savedViewsRepository.search(query).then((results: any) => {
            return results;
        });
    };

    const handleIconToggle = (update: any) => {
        setIsLoading(true);
        delete update.target;
        props
            .onUpdateView(update)
            .then(() => {
                setReload(true);
                setIsLoading(false);
            })
            .catch(() => {
                setIsLoading(false);
            });
    };

    const onUpdateProfile = async (entity: SavedInboundView) => {
        // Cloning the saved view to avoid manipulation by the repository
        // For when we update the pinned views on the DG etc.
        const savedView = _.cloneDeep(entity);
        return savedViewsRepository
            .update(entity)
            .then(() => {
                showBanner({
                    message: `Your ${props.entityTypeSingular.toLowerCase()} has been successfully saved.`,
                    level: LogLevel.SUCCESS,
                });
                // Update pinned in underlying DG
                props.onTogglePinned(savedView);
                // data changed, reload data grid
                setReload(true);
                return true;
            })
            .catch((err) => {
                showBanner({
                    message: `Could not save ${props.entityTypeSingular.toLowerCase()} - ` + (err?.message ?? err),
                });
                return false;
            });
    };

    const onDeleteSelectedRows = (ids: number[]) => {
        return savedViewsRepository
            .delete(ids)
            .then(() => {
                props.onRowsDeleted();
                showBanner({
                    message:
                        ids.length === 1
                            ? `1 ${props.entityTypeSingular} was successfully deleted`
                            : `${ids.length} ${props.entityTypePlural} were successfully deleted`,
                    level: LogLevel.SUCCESS,
                });
                return true;
            })
            .catch((err) => {
                showBanner({
                    message: err.message,
                    level: LogLevel.ERROR,
                });
                return false;
            });
    };

    const filters: filterType.BasicFilter[] = [
        {
            title: `${props.entityTypeSingular} Owner`,
            name: 'created_by',
            loadOptions: () => {
                // Fetch all users for client account
                const query = new SearchQuery(1, 100, 'id', SortOrder.ASC, '');
                return userRepository
                    .search(query)
                    .then((results) => {
                        return {
                            options: _.map(results.results as UserType[], (user: UserType) => {
                                return OptionTypeBaseUserFormatter(user);
                            }),
                        };
                    })
                    .catch((err: any) => {
                        showBanner({
                            message: 'Failed to get users - ' + (err?.message ?? err),
                        });
                    });
            },
        },
    ];

    if (props.groupType) {
        filters.push({
            title: 'Folder',
            name: 'folders',
            loadOptions: async () => {
                const query = new SearchQuery(1, 1000);
                return customPropertiesClientGroupsRepository
                    .search(query)
                    .then((results: any) => {
                        return {
                            options: results.results.map((result: ClientGroup) => {
                                return { value: result.id, label: result.name };
                            }),
                        };
                    })
                    .catch((err: any) => {
                        return null;
                    });
            },
        });

        DefaultMarketingListColumns.push({
            key: 'client_group',
            name: 'Folder',
            sortable: true,
            width: '1fr',
            renderCell: (fprops) => {
                const clientGroupInfo = fprops.row.clientGroupInfo;
                const clientGroupName = clientGroupInfo ? clientGroupInfo.name : '';
                return <>{clientGroupName}</>;
            },
        });
    }

    const checkProfileNameIsUnique = async (name: string, id?: number): Promise<boolean> => {
        return savedViewsRepository
            .post_action('check_name', undefined, { name: name, id: id })
            .then((results: any) => {
                return results.data.result;
            })
            .catch(() => {
                showBanner({
                    message: `Unable to check ${props.entityTypeSingular} name`,
                    level: LogLevel.ERROR,
                });
                EventBusInstance.publish({
                    topic: 'show-hoverover-component',
                    message: '',
                    target: 'hide-edit-modal',
                });
                return false;
            });
    };

    const getFolderOptions = async (filter: string) => {
        const query = new SearchQuery(1, 1000, undefined, undefined, filter);
        return customPropertiesClientGroupsRepository
            .search(query)
            .then((results: any) => {
                return {
                    options: results.results.map((result: ClientGroup) => {
                        return { value: result.id, label: result.name };
                    }),
                };
            })
            .catch((err: any) => {
                return null;
            });
    };

    return (
        <Modal
            className="views-modal"
            close={() => props.onClose()}
            isOpen={props.modalShown}
            title={props.title}
            type={ModalType.INFO}
            fullScreen={true}
        >
            <Banner />
            <SavedViewsGrid
                onDeleteSelectedRows={onDeleteSelectedRows}
                onGetRows={onGetRows}
                columns={columns}
                filters={filters}
                onUpdateProfile={onUpdateProfile}
                user={user}
                reload={reload}
                loading={isLoading}
                defaultSortColumn={'favourited'}
                onFilterChange={setFilterState}
                peopleTableType={undefined}
                addEditModalType={
                    props.savedViewType == SavedViewType.MARKETING_LIST
                        ? ProfileAddEditModalType.LIST
                        : ProfileAddEditModalType.SAVED_SEARCH
                }
                entityTypeSingular={props.entityTypeSingular}
                entityTypePlural={props.entityTypePlural}
                checkProfileNameIsUnique={checkProfileNameIsUnique}
                getAvailableFolders={userPermissions.clientGroupsInboundLists.isEnabled ? getFolderOptions : undefined}
            />
        </Modal>
    );
};

export default ViewsModal;
