import { isValidString, LogLevel, lookupValidator, showBanner, Sisp } from '@sprint/sprint-react-components';
import _ from 'lodash';
import React, { FormEvent, FunctionComponent, useContext, useEffect, useRef, useState } from 'react';
import { Form } from 'react-bootstrap';
import { UserTypeRequest } from '../../Api/UserTypeRequest';
import { PermissionsContext, RepositoryFactoryContext } from '../../index';
import UserType from '../../Models/UserType';

interface Props {
    shown: boolean;
    onClose: () => void;
    onSuccess: (event: any) => Promise<boolean>;
}

const UsersAddSisp: FunctionComponent<Props> = (props: Props) => {
    const usersRepository = useContext(RepositoryFactoryContext).getApiRepository(new UserTypeRequest());
    const permissions = useContext(PermissionsContext);

    const focusRef = useRef<HTMLInputElement>(null);

    const [newEmail, setNewEmail] = useState<string>('');
    const [newFirstName, setNewFirstName] = useState<string>('');
    const [newLastName, setNewLastName] = useState<string>('');
    const [newRequires2fa, setNewRequires2fa] = useState<boolean>(false);
    const [newRequiresOAuth, setNewRequiresOAuth] = useState<boolean | null>(null);
    const [newBillingUser, setNewBillingUser] = useState<boolean>(false);

    const newUserTypeDefault = {
        admin: false,
        manager: false,
        user: false,
    };
    const [newUserType, setNewUserType] = useState(newUserTypeDefault);
    const [newPersonalMessage, setNewPersonalMessage] = useState<string>('');

    const validationStateDefault = {
        email: true,
        firstName: true,
        lastName: true,
        userType: true,
    };
    const [validationState, setValidationState] = useState(validationStateDefault);

    const [validationFeedback, setValidationFeedback] = useState<Record<string, string | JSX.Element>>({
        email: '',
    });

    useEffect(() => {
        if (props.shown) {
            focusRef.current?.focus();
        }
    }, [props.shown]);

    const reset = () => {
        setNewEmail('');
        setNewFirstName('');
        setNewLastName('');
        setNewRequires2fa(false);
        setNewBillingUser(false);
        setNewRequiresOAuth(null);
        setNewUserType(newUserTypeDefault);
        setNewPersonalMessage('');
        setValidationState(validationStateDefault);
    };

    const updateUserType = (userType: string) => {
        setNewUserType({ ...newUserTypeDefault, [userType]: true });
    };

    const checkEmailIsUnique = async (email: string): Promise<boolean> => {
        return usersRepository
            .post_action('check_email', undefined, { email: email })
            .then((results: any) => {
                setValidationFeedback((prevState) => {
                    return {
                        ...prevState,
                        email: results.data.result ? '' : 'Sorry, this email address is already in use.',
                    };
                });
                return results.data.result;
            })
            .catch((err) => {
                setValidationFeedback((prevState) => {
                    return { ...prevState, email: 'Email address check failed' };
                });
                return false;
            });
    };

    const validate = async (): Promise<boolean> => {
        const firstNameValid = !!newFirstName && isValidString(newFirstName);
        const lastNameValid = !!newLastName && isValidString(newLastName);
        const userTypeValid = _.some(newUserType);

        let emailValid = false;
        if (newEmail !== '') {
            const emailValidator = lookupValidator('email', 'text');
            emailValid = emailValidator.validate(newEmail);
            setValidationFeedback((prevState) => {
                return { ...prevState, email: emailValid ? '' : emailValidator.feedback('Email') };
            });
            if (emailValid) {
                emailValid = await checkEmailIsUnique(newEmail);
            }
        } else {
            setValidationFeedback((prevState) => {
                return { ...prevState, email: 'This field is required.' };
            });
        }

        const newValidationState = {
            email: emailValid,
            firstName: firstNameValid,
            lastName: lastNameValid,
            userType: userTypeValid,
        };
        setValidationState(newValidationState);

        return _.every(newValidationState);
    };

    const handleAddRow = async (): Promise<boolean> => {
        let newUserTypeStr = '';
        for (const [key, value] of Object.entries(newUserType)) {
            if (value) {
                newUserTypeStr = key;
                break;
            }
        }

        const UserType: UserType = {
            email: newEmail,
            first_name: newFirstName,
            last_name: newLastName,
            requires_2fa: newRequires2fa,
            billing_user: newBillingUser,
            requires_oauth: newRequiresOAuth,
            level: newUserTypeStr,
            personal_message: newPersonalMessage,
        };

        return usersRepository
            .create(UserType)
            .then((results: any) => {
                showBanner({
                    message: results.data.alert,
                    level: LogLevel.SUCCESS,
                });
            })
            .then(props.onSuccess)
            .then(async (success) => {
                reset();
                return success;
            })
            .catch((err) => {
                showBanner({
                    message: 'Failed to create user - ' + (err?.message ?? err),
                    level: LogLevel.ERROR,
                });
                return false;
            });
    };

    const onSubmitForm = async (e: FormEvent) => {
        e.preventDefault();
        if ((await validate()) && (await handleAddRow())) props.onClose();
    };

    return (
        <Sisp
            className="users-sisp-add"
            isOpen={props.shown}
            onSubmit={handleAddRow}
            onCancel={() => {
                reset();
                props.onClose();
            }}
            validate={validate}
        >
            <h4>Invite a User</h4>
            <Form onSubmit={onSubmitForm}>
                <Form.Group>
                    <Form.Label>
                        Email Address <span className="required-field-marker">*</span>
                    </Form.Label>
                    <Form.Control
                        autoComplete="off"
                        name="emailaddress"
                        ref={focusRef}
                        type="text"
                        isInvalid={!validationState.email}
                        value={newEmail || ''}
                        onChange={(event) => {
                            setNewEmail(event.target.value);
                            setValidationState((prevState) => {
                                return { ...prevState, email: true };
                            });
                        }}
                    />
                    <Form.Control.Feedback type="invalid">
                        {!validationState.email && validationFeedback.email}
                    </Form.Control.Feedback>
                </Form.Group>
                <Form.Group>
                    <Form.Label>
                        First Name <span className="required-field-marker">*</span>
                    </Form.Label>
                    <Form.Control
                        autoComplete="off"
                        name="firstname"
                        type="text"
                        isInvalid={!validationState.firstName}
                        value={newFirstName || ''}
                        onChange={(event) => {
                            setNewFirstName(event.target.value);
                            setValidationState((prevState) => {
                                return { ...prevState, firstName: true };
                            });
                        }}
                    />
                    <Form.Control.Feedback type="invalid">
                        {!validationState.firstName && 'This field is required.'}
                    </Form.Control.Feedback>
                </Form.Group>
                <Form.Group>
                    <Form.Label>
                        Last Name <span className="required-field-marker">*</span>
                    </Form.Label>
                    <Form.Control
                        autoComplete="off"
                        name="lastname"
                        type="text"
                        isInvalid={!validationState.lastName}
                        value={newLastName || ''}
                        onChange={(event) => {
                            setNewLastName(event.target.value);
                            setValidationState((prevState) => {
                                return { ...prevState, lastName: true };
                            });
                        }}
                    />
                    <Form.Control.Feedback type="invalid">
                        {!validationState.lastName && 'This field is required.'}
                    </Form.Control.Feedback>
                </Form.Group>
                <Form.Group>
                    <Form.Label>Billing</Form.Label>
                    <Form.Check
                        custom={true}
                        id={'user-billing-user'}
                        type="switch"
                        checked={newBillingUser}
                        label="Billing User"
                        onChange={(event) => {
                            setNewBillingUser(event.target.checked);
                        }}
                    />
                </Form.Group>
                {permissions['two_factor_visible'] && (
                    <Form.Group>
                        <Form.Label>Two Factor Authentication</Form.Label>
                        <Form.Check
                            custom={true}
                            id={'user-require-2fa'}
                            disabled={!permissions['two_factor_enabled']}
                            type="switch"
                            checked={newRequires2fa}
                            label="Require Two Factor Authentication"
                            onChange={(event) => {
                                setNewRequires2fa(event.target.checked);
                            }}
                        />
                    </Form.Group>
                )}
                <Form.Group>
                    <Form.Label>OAuth Login</Form.Label>
                    <Form.Check
                        custom={true}
                        id={'user-require-oauth'}
                        disabled={false}
                        type="switch"
                        checked={newRequiresOAuth ?? false}
                        label="Require OAuth-based Login"
                        onChange={(event) => {
                            setNewRequiresOAuth(event.target.checked);
                        }}
                    />
                </Form.Group>
                <Form.Group>
                    <Form.Label>
                        User Type <span className="required-field-marker">*</span>
                    </Form.Label>
                    <Form.Check
                        id="user-type-admin"
                        name="user-type"
                        type="radio"
                        label="Admin (like you)"
                        custom
                        checked={newUserType.admin}
                        onChange={() => updateUserType('admin')}
                    />
                    <Form.Check
                        id="user-type-manager"
                        name="user-type"
                        type="radio"
                        label="Manager (has full access except to the billing areas)"
                        custom
                        checked={newUserType.manager}
                        onChange={() => updateUserType('manager')}
                    />
                    <Form.Check
                        id="user-type-user"
                        name="user-type"
                        type="radio"
                        label="User (cannot change any fundamental settings)"
                        custom
                        checked={newUserType.user}
                        onChange={() => updateUserType('user')}
                    />
                    {!validationState.userType && (
                        <Form.Control.Feedback type="invalid">Please select a User Type.</Form.Control.Feedback>
                    )}
                </Form.Group>
                <Form.Group>
                    <Form.Label>Add a Personal Message</Form.Label>
                    <Form.Control
                        as="textarea"
                        style={{
                            minHeight: '5rem',
                        }}
                        value={newPersonalMessage}
                        onChange={(event) => setNewPersonalMessage((event.target as HTMLTextAreaElement).value)}
                    />
                </Form.Group>
            </Form>
        </Sisp>
    );
};

export default UsersAddSisp;
