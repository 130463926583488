import { RepositoryFactoryContext } from '@client/Applications/DataGrids';
import { DealsRequest } from '@client/Applications/DataGrids/Api/DealsRequest';
import {
    ActionBarMeta,
    AddSispMeta,
    DataGridMeta,
    EditSispMeta,
    PreviewSispMeta,
    PromptMeta,
    ViewsMeta,
} from '@client/Applications/DataGrids/Grids/CampusDataGrid';
import DealsBoardWrapper from '@client/Applications/DataGrids/Grids/Deals/DealsBoardWrapper';
import DealsEditSisp from '@client/Applications/DataGrids/Grids/Deals/DealsEditSisp';
import DealsPreviewSisp from '@client/Applications/DataGrids/Grids/Deals/DealsPreviewSisp';
import React, { FunctionComponent, useContext } from 'react';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { configureStore } from '../../../Deals/configureStore';

interface Props {
    actionBarMeta: ActionBarMeta;
    addSispMeta: AddSispMeta;
    editSispMeta: EditSispMeta;
    previewSispMeta: PreviewSispMeta;
    dataGridMeta: DataGridMeta;
    viewsMeta: ViewsMeta;
    promptMeta: PromptMeta;
    previewSispUniqueKey: string;
}

const DealsBoardPersistor: FunctionComponent<Props> = (props: Props) => {
    const { store, persistor } = configureStore();

    const dealsRepository = useContext(RepositoryFactoryContext).getApiRepository(new DealsRequest());

    return (
        <div id="DealBoard">
            <Provider store={store}>
                <PersistGate persistor={persistor}>
                    <DealsBoardWrapper
                        repository={dealsRepository}
                        actionBarMeta={props.actionBarMeta}
                        addSispMeta={props.addSispMeta}
                        editSispMeta={{ sisp: DealsEditSisp }}
                        previewSispMeta={{ sisp: DealsPreviewSisp, key: props.previewSispUniqueKey }}
                        dataGridMeta={props.dataGridMeta}
                        viewsMeta={props.viewsMeta}
                        promptMeta={props.promptMeta}
                    />
                </PersistGate>
            </Provider>
        </div>
    );
};

export default DealsBoardPersistor;
